import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RelatorioPrecoRoutingModule } from './relatorio-preco-routing.module';
import { RelatorioListComponent } from './relatorio-list/relatorio-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CheckboxModule } from 'src/app/ui/checkbox/checkbox.module';
import { InputModule } from 'src/app/ui/input/input.module';
import { SelectModule } from 'src/app/ui/select/select.module';
import { IconModule } from 'src/app/ui/icon/icon.module';
import { CollapseModule } from 'src/app/ui/collapse/collapse.module';
import { PaginatorModule } from 'src/app/ui/paginator/paginator.module';
import { TabModule } from 'src/app/ui/tab/tab.module';
import { CardModule } from 'src/app/ui/card/card.module';
import { TableSortModule } from 'src/app/ui/table-sort/table-sort.module';
import { EmptyPatternModule } from 'src/app/ui/empty-pattern/empty-pattern.module';

@NgModule({
  declarations: [
    DashboardComponent,
    RelatorioListComponent
  ],
  imports: [
    CommonModule,
    RelatorioPrecoRoutingModule,
    SharedModule,
    CheckboxModule,
    InputModule,
    IconModule,
    CollapseModule,
    PaginatorModule,
    TabModule,
    CardModule,
    TableSortModule,
    EmptyPatternModule,
    SelectModule  
  ]
})
export class RelatorioPrecoModule { }
