import { Directive, AfterViewInit, OnDestroy, Input, Optional } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgControl, ControlContainer, AbstractControl } from '@angular/forms';
import { startWith, map, debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[mapControl], [mapControlName]'
})
export class MapControlDirective implements AfterViewInit, OnDestroy {
  private subscription: Subscription;

  @Input() mapControl: NgControl | AbstractControl;
  @Input() mapControlName: string;

  @Input() mapControlMapper = (value: string) => {
    return value;
  }

  constructor(
    private ngControl: NgControl,
    @Optional() private controlContainer: ControlContainer,
  ) {
  }

  ngAfterViewInit() {
    if (this.mapControlName) {
      this.mapControl = this.controlContainer.control.get(this.mapControlName);
    }

    this.mapControl.valueChanges.pipe(
      startWith(this.mapControl.value),
      // debounceTime(500),
      map( value => this.mapControlMapper(value || '') )
    ).subscribe( value => {
      if (this.ngControl) {
        this.ngControl.control.setValue(value, /*{ emitEvents: false }*/);
      }
    });
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
