import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from './session.service';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IClaim } from '../models/claim/claim.interface';
import { ISession } from '../models/session/session.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuthenticated$ = this.sessionServ.session$.pipe(
    map(session => this.isAuthenticated(session))
  );

  constructor(
    private router: Router,
    private sessionServ: SessionService
  ) {
  }

  isAuthenticated(session = this.sessionServ.getSession()) {
    if (session) {
      const now = moment();
      const expirationDate = session && moment(new Date(session.expiration * 1000));
      return session && session.accessToken && now.isBefore(expirationDate);
    } else {
      return false;
    }
  }

  hasPermission(permissionName: string) {
    const user = this.sessionServ.user;

    if (permissionName && user && user.permissions) {
      const permissions = user.permissions;

      if (permissions.find(elem => elem === permissionName || elem === `${environment.permissionPrefix}.*`)) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  }

  login(token: string, login: string) {
    const claim: IClaim = JSON.parse(atob(token.split('.')[1]));
    const claimRaw = JSON.parse(atob(token.split('.')[1]));

    // Create user session
    const session = {
      accessToken: token,
      user: {
        id: claim.usuario_id,
        email: login,
        name: claim.given_name,
        //permissions: claim.permission ? JSON.parse(claim.permission) : [],
        permissions:claimRaw["resource_access"]["portal-smart"]["roles"],
        role: claim.role,
        fantasyName: claim.nome_fantasia,
        sapCode: claim.codigo_sap
      },
      expiration: claim.exp,
    } as ISession;

    // Set session
    this.sessionServ.setSession(session);

    this.router.navigateByUrl('/');
  }

  logout() {
    this.sessionServ.reset();
    this.router.navigateByUrl('/login');
  }

}
