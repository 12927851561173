import { OnInit, Directive, EventEmitter } from '@angular/core';

@Directive({
  selector: '[table-sort]'
})
export class TableSortDirective implements OnInit {

  changeEvent = new EventEmitter<any>();

  private sortProperties = new Map<string, number>();

  constructor() { }

  ngOnInit() {
  }

  setPropertieAscending(prop: string) {
    this.sortProperties.set(prop, 1);
    this.updateValue();
  }

  setPropertieDescending(prop: string) {
    this.sortProperties.set(prop, -1);
    this.updateValue();
  }

  removePropertie(prop: string) {
    this.sortProperties.delete(prop);
    this.updateValue();
  }

  updateValue() {
    // ATTENTION
    // This code bellow is necessary on this way !!!
    // tslint:disable-next-line: no-string-literal
    this.changeEvent.emit(Object['fromEntries'](this.sortProperties));
    console.log(Object['fromEntries'](this.sortProperties));
  }

}
