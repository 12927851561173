import { Injectable } from '@angular/core';
import { ApiHttpPricefyClientService } from '../../api-http-pricefy-client.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth.service';
import { HttpParams,HttpHeaders } from '@angular/common/http';
import { Lote } from 'src/app/lib/dynamic-form/models/api/pricefy/lote';
import { LotePricefy } from 'src/app/lib/dynamic-form/models/api/pricefy/lotePricefy';
import { DinamicaPricefy } from 'src/app/lib/dynamic-form/models/api/pricefy/dinamicaPricefy';


@Injectable({
  providedIn: 'root'
})
export class ApiPricefyService {

  private baseUrl = '';
  private controller: ApiHttpPricefyClientService;

    constructor(
    private api: ApiHttpPricefyClientService,
    private authServ: AuthService
  ) {
    this.controller = this.api
      .useBaseUrl(this.baseUrl);

  }

  obterLote(useLoading : boolean, id:string) {
    
    let request = this.controller;
                
    if(useLoading){
      request = request.useLoading()
    }

    return request      
      .get<Lote>(`/lote/${id}`);
  }

 
  adicionarSkus(useLoading : boolean,lote:Lote) {
    
    let request = this.controller;

    if(useLoading){
      request = request.useLoading()
    }


    return request      
      .post<Lote> ('/lote/sku',lote);
  }


  excluirSkus(useLoading : boolean,lote:Lote) {
    let request = this.controller;
    const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: lote
      };
      
    if(useLoading){
      request = request.useLoading()
    }

    return request      
      .delete<Lote>('/lote/sku',options);
  }

  statusLote(useLoading : boolean,id:string,loja:string) {
    let request = this.controller;

      
    if(useLoading){
      request = request.useLoading()
    }

    return request      
      .get<LotePricefy>(`/lote/${id}/loja/${loja}}`);
  }

  excluirLote(useLoading : boolean,id:string,loja:string) {
    let request = this.controller;

      
    if(useLoading){
      request = request.useLoading()
    }

    return request      
      .delete(`/lote/${id}/loja/${loja}}`);
  }

  processarLote(useLoading:boolean,id:string){
    let request = this.controller;

      
    if(useLoading){
      request = request.useLoading()
    }      

    return request
        .post<Lote>(`/lote/${id}`,null);
  }

  obterDinamicas(useLoading:boolean){
    let request = this.controller;

    if(useLoading){
      request = request.useLoading()
    }      

    return request
        .get<DinamicaPricefy[]>(`/lote/dinamica`);    
  }

  obterDinamica(useLoading:boolean,codigo:string){
    let request = this.controller;

    if(useLoading){
      request = request.useLoading()
    }      

    return request
        .get<DinamicaPricefy>(`/lote/dinamica/${codigo}`);    
  }
}
