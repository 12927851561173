import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal/modal.component';
import { ModalService } from './modal.service';
import { IconModule } from '../icon/icon.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ConfirmDialogService } from './confirm-dialog.service';
import { AlertDialogService } from './alert-dialog.service';
import { InputModule } from '../input/input.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkTableModule } from '@angular/cdk/table';
import { TableSortModule } from '../table-sort/table-sort.module';
import { DanfeDialogComponent } from './danfe-dialog/danfe-dialog.component';
import { DanfeDialogService } from './danfe-dialog.service';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { LoadingModule } from '../loading/loading.module';
import { EmptyPatternModule } from '../empty-pattern/empty-pattern.module';
import { ConfigurationDialogComponent } from './configuration-dialog/configuration-dialog.component';
import { ConfigurationDialogService } from './configuration-dialog.service';
import { NotaservicoDialogComponent } from './notaservico-dialog/notaservico-dialog.component';
import { NotaServicoDialogService} from "./notaservico-dialog.service"

@NgModule({
  declarations: [
    ModalComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    DanfeDialogComponent,
    ConfigurationDialogComponent,
    NotaservicoDialogComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    InputModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    TableSortModule,
    PipesModule,
    LoadingModule,
    EmptyPatternModule
  ],
  entryComponents: [
    ModalComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    DanfeDialogComponent,
    ConfigurationDialogComponent,
    NotaservicoDialogComponent
  ],
  providers: [
    ModalService,
    ConfirmDialogService,
    AlertDialogService,
    DanfeDialogService,
    ConfigurationDialogService,
    NotaServicoDialogService
  ],
  exports: [
    ModalComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    DanfeDialogComponent,
    ConfigurationDialogComponent,
    NotaservicoDialogComponent
  ]
})
export class ModalModule { }
