import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ApiEstoqueService } from 'src/app/services/api/estoque/api-estoque.service';
import { Estoque } from 'src/app/lib/dynamic-form/models/api/estoque/estoque';
import { ToastType } from 'src/app/ui/toast/toast.model';

@Component({
  selector: 'c-consulta-estoque',
  templateUrl: './consulta-estoque.component.html',
  styleUrls: ['./consulta-estoque.component.scss']
})
export class ConsultaEstoqueComponent implements AfterViewInit {

  defaultTableColumns: string[] = [
    'nomeCompleto', 'skuSap', 'skuVtex',
    'estoqueSAP', 'estoqueVtex', 'saldoEstoque',
    'protecaoEstoque', 'sortido', 'bloqueioOmni',
    'ativoVtex', 'lojaCentro'
  ];
  skuSap: any ='';
  centro: any = '';
  skuVtex: any ='';
  dataSource: Estoque[] = [];
  page: number = 1;
  take: number = 25;

  constructor(private apiEstoque: ApiEstoqueService,
    private toastService: ToastService
  ) {

  }

  ngAfterViewInit() {
  }
  buscar(acao:number) {



    let listaSap : string[] = this.skuSap.trim().split(/\s+/);

    if(listaSap.length > 25){
      this.toastService.toast("Por favor envie menos de 25 skus sap por vez.", ToastType.error);
      return;
    }

    
    listaSap = listaSap.filter(function (x, i, a) { 
      if(x == ''){
        return false;
      }
      return a.indexOf(x) === i; 

    });


    let listaVtex : string[] =   this.skuVtex.trim().split(/\s+/);

    if(listaVtex.length > 25){
      this.toastService.toast("Por favor envie menos de 25 skus vtex por vez.", ToastType.error);
      return;
    }

    listaVtex = listaVtex.filter(function (x, i, a) { 
      if(x == ''){
        return false;
      }
      return a.indexOf(x) === i; 

    });

    if(acao == 0 && this.page > 1){
      this.page -= 1;
    }
    if(acao == 2){
      this.page += 1;
    }
    if(this.centro == ''){
      this.toastService.toast("Centro obrigatório.", ToastType.error);
      return;
    }
    if(listaSap.length == 0 && listaVtex.length == 0){
      
      this.toastService.toast("Envie 1 ou mais skus para consulta.", ToastType.error);
      return;
    }

    this.apiEstoque.obterEstoque(true, listaSap, listaVtex, this.centro,this.page,this.take).subscribe(
      (res) => {
        this.dataSource = res.stock;
      },
      (err) => {
      }
    );
  }


}