import { Component, OnInit, Input, Output, EventEmitter , ViewChild,TemplateRef} from '@angular/core';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { Pagamento } from 'src/app/lib/dynamic-form/models/api/pagamento/pagamento';
import { ApiPagamentoService } from "src/app/services/api/pagamento/api-pagamento.service";
import { ToastType } from 'src/app/ui/toast/toast.model';
import { ModalService } from 'src/app/ui/modal/modal.service';

@Component({
  selector: 'c-pagamento-list-table',
  templateUrl: './pagamento-list-table.component.html',
  styleUrls: ['./pagamento-list-table.component.scss']
})
export class PagamentoListTableComponent implements OnInit {
  @ViewChild('tplHistorico',{static:false}) tplHistorico: TemplateRef<any>;
  @Output() listUpdated = new EventEmitter();
  @Output() setFocus = new EventEmitter();
  @Input() columns: string[];
  @Input() dataSource: object[];

  @Output() sortEvent = new EventEmitter<{ [x: string]: number }>();

  historico :any[] = [];

  constructor(private toastService : ToastService,
    private apiPagamentoService:ApiPagamentoService,
    private modalService:ModalService) { }

  ngOnInit() {
  }

  updateSort(data: { [x: string]: number }) {    
    this.sortEvent.emit(data);
  }

  addDays(date:any,days:Number){
    let dt:Date;
    dt = new Date(date);
    dt.setDate(dt.getDate() + 2);
    return dt;
  }
  visualizarHistorico(pagamento:Pagamento){
    this.historico=[];
    this.apiPagamentoService.buscaHistorico(true,pagamento.Id)
    .subscribe(
      (res:any)=>{
        this.historico=res.data;
        this.modalService.open(this.tplHistorico);
      },
      (err:any)=>{

      }
    ); 
  }

  estornarBeneficios(pagamento:Pagamento){
    
    this.apiPagamentoService.estornaCashback(true,pagamento.AriusId,pagamento.LojaCnpj,pagamento.Document,pagamento.DataCadastro,pagamento.Price,pagamento.IdPedido)
    .subscribe((res :any) => {       
      if(res.data != null){
        if(res.data.returnStatusId == 1 || res.data.returnStatusId == -2){
          let msg = "";
          switch(res.data.returnStatusId){
            case 1: msg = "Estorno realizado do pedido "; break;
            case -2: msg = "Estorno ja tinha sido realizado anteriormente do pedido "; break;
          }          
            pagamento.BeneficioEstornado = true;
            this.toastService.toast(msg +pagamento.IdPedido, ToastType.success);                    
        }
        
      }else{
        this.toastService.toast(res.message, ToastType.error);
      };
    },
    (error : any) => {
      
      this.toastService.toast("Erro ao estornar a transação", ToastType.error);
     
    });
  }
}
