import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { IPaginator } from 'src/app/ui/paginator/paginator.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { IResponse } from 'src/app/models/api/response.interface';
import { skip, debounceTime } from 'rxjs/operators';
import { ApiPedidoService } from 'src/app/services/api/pedido/api-pedido.service';
import { Pedido } from 'src/app/lib/dynamic-form/models/api/pedido/pedido';
import { InputComponent } from 'src/app/ui/input/input/input.component';
import { EnumTabStatus } from 'src/app/services/api/pedido/enum-tab-status.enum';
import { ConfirmDialogService } from 'src/app/ui/modal/confirm-dialog.service';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ToastType } from 'src/app/ui/toast/toast.model';
import { DanfeDialogService } from 'src/app/ui/modal/danfe-dialog.service';
import { EnumOrdenacao } from 'src/app/services/api/pedido/enum-ordenacao.enum';

@Component({
  selector: 'c-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements AfterViewInit {

  orders = []
  count = 0;

  leituraPedido$ = new BehaviorSubject('');
  pedidoParaAbrir: Subject<string> = new Subject();

  @ViewChild(InputComponent, {static: true}) searchField: InputComponent;

  regexNumeroPedido = /v[0-9]+r[i]?hp-[0-9]{2}/g

  columnFilters$ = new BehaviorSubject<any>([0, 1, 2]);
  columnFilters = ['pedido', 'centro', 'data'];

  defaultTableColumns: string[] = ['pedido', 'centro', 'data', 'actions'];

  cancelStatuses : string[] = ["cancel", "canceled"]

  interval : any;

  tabs!: {
    name: string;
    status: EnumTabStatus;
    ordenacao: EnumOrdenacao;
    count: number;
    orders: Pedido[];
    permission: string;
    paginator: BehaviorSubject<IPaginator>
  }[];

  constructor(private apiPedidoService : ApiPedidoService,
    private confirmDialogService: ConfirmDialogService,
    private danfeDialogService: DanfeDialogService,
    private toastService : ToastService
  ) {
    this.createTabs();
  }

  ngAfterViewInit() {
    this.buscaPedidosPendentes(true);
    this.iniciaTimerRefresh();
    this.configurarCampoLeituraPedido();
  }

  reload(paginator: IPaginator, tab : any) {
    tab.paginator.next(paginator);
    this.buscaPedidos(tab, true);
  }

  iniciaTimerRefresh() {
    if(this.interval){
      clearInterval(this.interval);
    }

    this.interval = setTimeout(() => {
      this.buscaPedidosPendentes(false);
    }, 5000);
  }

  ngOnDestroy(){
    if(this.interval){
      clearInterval(this.interval);
    }
  }

  configurarCampoLeituraPedido() {
    this.leituraPedido$.pipe(skip(1)).subscribe(() => {
      if(this.leituraPedido$.value.match(this.regexNumeroPedido)){
        this.abrePedido(this.leituraPedido$.value);
        
        setTimeout(() => {
          this.leituraPedido$.next("");
        }, 100);
      }
    });
  }

  buscaPedidosPendentes(useLoading : boolean) {    
    for(let tab of this.tabs){
      this.buscaPedidos(tab, useLoading)
    }
  }

  buscaPedidos(tab : any, useLoading : boolean){
    const paginator = tab.paginator.value;

    let skip = (paginator.page - 1) * paginator.pageLength;
    let take = paginator.pageLength;
    
    this.apiPedidoService.buscaPedidosPendentes(useLoading, tab.status, skip, take, tab.ordenacao).subscribe((res : any) => {      
      this.createTabData(res, tab.status);
      this.iniciaTimerRefresh()
    }, (err : any) => {
      this.iniciaTimerRefresh()
    });
  }

  abrePedido(idPedido : string){
      let orderId= idPedido.split('-')[0];
      let fulfillmentId= idPedido.split('-')[1];      

      let p : Pedido = null;

      for(var tab of this.tabs){
        p = tab.orders.find(x => {
          return x.OrderId == orderId && x.Id == fulfillmentId;
        });

        if(p != null){

          if(this.cancelStatuses.includes(p.PedidoVtex.Status)){
            this.toastService.toast("Pedido \""+orderId+"-"+fulfillmentId+"\" está CANCELADO na VTEX.", ToastType.error);
            return;
          }

          if(p.EnviadoParaOms){
            this.abreDanfe(p);
          }else{
            this.confirmarQuantidade(p);
          }
  
          return        
        }
      }
      
      this.apiPedidoService.buscaPedido(true, orderId, fulfillmentId).subscribe((res : any) => {        
        if(res.Data != null){
          
          p = res.Data as Pedido;

          if(this.cancelStatuses.includes(p.PedidoVtex.Status)){
            this.toastService.toast("Pedido \""+orderId+"-"+fulfillmentId+"\" está CANCELADO na VTEX.", ToastType.error);
            return;
          }

          if(p.EnviadoParaOms){
            this.abreDanfe(p);
          }else{
            this.confirmarQuantidade(p);
          }            
          return;
        }        
        this.toastService.toast("Pedido \""+orderId+"-"+fulfillmentId+"\" não encontrado.", ToastType.error);
      });              
  }

  confirmarQuantidade(pedido : Pedido){    
    if(this.cancelStatuses.includes(pedido.PedidoVtex.Status)){
      this.toastService.toast("Pedido \""+pedido.OrderId+"-"+pedido.Id+"\" está CANCELADO na VTEX.", ToastType.error);
      return;
    }
    
    this.confirmDialogService.confirm("Pedido: "+pedido.OrderId+"-"+pedido.Id, pedido)
    .then((send) => {
      if(send){        
          this.buscaPedidosPendentes(true);       
      }
      this.setFocus();
    })
    .catch((err) => {console.log(err)})
  }

  abreDanfe(pedido: Pedido){
    
    if(this.cancelStatuses.includes(pedido.PedidoVtex.Status)){
      this.toastService.toast("Pedido \""+pedido.OrderId+"-"+pedido.Id+"\" está CANCELADO na VTEX.", ToastType.error);
      return;
    }
    
    this.danfeDialogService.confirm("Pedido: "+pedido.OrderId+"-"+pedido.Id, pedido)
    .then((send) => {
      if(send){        
          this.buscaPedidosPendentes(true);       
      }
      this.setFocus();
    })
    .catch((err) => {console.log(err)})    
  }

  setFocus(){
    setTimeout(() => {      
      this.searchField.focus();
    });
  }

  createTabData(data: any, status: EnumTabStatus) {
    const tab = this.tabs.find(elem => elem.status == status);

    if (tab) {      
      tab.orders = data.Data;
      tab.count = data.Count;
    }
  }

  private createTabs() {
    this.tabs = [
      {
        name: 'pendente',
        status: EnumTabStatus.pendente,
        ordenacao: EnumOrdenacao.ascendente,
        count: 0,
        orders: [],
        permission: 'wms.pedido.listarPendentes',
        paginator : new BehaviorSubject<IPaginator>({
          isLastPage: false,
          page: 1,
          pageLength: 25,
          total: 0
        })
      },
      {
        name: 'faturado',
        status: EnumTabStatus.faturado,
        ordenacao: EnumOrdenacao.descendente,
        count: 0,
        orders: [],
        permission: 'wms.pedido.listarFaturados',
        paginator : new BehaviorSubject<IPaginator>({
          isLastPage: false,
          page: 1,
          pageLength: 25,
          total: 0
        })
      }
    ];
  }
}
