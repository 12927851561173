import { Component, AfterViewInit, ViewChild, ElementRef,Output,EventEmitter } from "@angular/core";
import { IPaginator } from "src/app/ui/paginator/paginator.component";
import { BehaviorSubject, Subject } from "rxjs";
import { InputComponent } from "src/app/ui/input/input/input.component";
import { SelectComponent } from "src/app/ui/select/select/select.component";
import { SelectOptionComponent } from "src/app/ui/select/select-option/select-option.component";
import { ToastService } from "src/app/ui/toast/toast.service";
import { ToastType } from 'src/app/ui/toast/toast.model';
import { EnumOrdenacao } from "src/app/services/api/pedido/enum-ordenacao.enum";
import { first } from 'rxjs/operators';
import { ApiTransferenciaServicoService } from 'src/app/services/api/transferencia/api-transferencia.service'
import { PedidoTransferencia } from "src/app/lib/dynamic-form/models/api/transferencia/pedidoTransferencia";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'c-transferencia-list',
  templateUrl: './transferencia-list.component.html',
  styleUrls: ['./transferencia-list.component.scss']
})
export class TransferenciaListComponent implements AfterViewInit {
  @Output() setFocus = new EventEmitter();

  defaultTableColumns: string[] = [
    "Transferir",
    "ChaveNfe",
    "CnpjDestino",
    "NomeFornecedor",
    "Endereco",
    "Bairro",
    "Cidade",
    "Estado",
    "Cep",
    "Pedido",
    "DtEmissao",    
    "Actions"
  ];

  from:string;
  to:string;

  tabs!: {
    name: string;
    status: string;
    ordenacao: EnumOrdenacao;
    count: number;
    pedidos: PedidoTransferencia[];
    permission: string;
    paginator: BehaviorSubject<IPaginator>;
  }[];

  constructor(
    private apiTransferenciaService:ApiTransferenciaServicoService,
    private toastService: ToastService
  ) { 
    this.createTabs();    
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.from = firstDay.toJSON().slice(0,10);
    this.to = lastDay.toJSON().slice(0,10);
  }

 

  ngAfterViewInit() {

  }

  createTabData(data: any,status:string) {
    const tab = this.tabs.find((elem) => elem.status == status);

    if (tab) {
      tab.pedidos = data;
      tab.count = data.length;
      
    }
  }

  reload(paginator: IPaginator, tab: any) {
    tab.paginator.next(paginator);
    this.buscarPedidos(tab, true);
  }

  updateSort(
    data: { [x: string]: number },
    paginator: IPaginator,
    status: string
  ) {
    const tab = this.tabs.find((elem) => {
      return elem.status == status;
    });

    if (data.DataCadastro) {
      if (data.DataCadastro > 0) {
        tab.ordenacao = EnumOrdenacao.descendente;
      } else {
        tab.ordenacao = EnumOrdenacao.ascendente;
      }

      
    }
  } 

  buscaPedidos(useLoading: boolean) {
    for (let tab of this.tabs) {
      this.buscarPedidos(tab, useLoading);
    }
  }

  transferencia(){
    this.apiTransferenciaService
    .Transferir(
      true,
      this.from,
      this.to
    ).subscribe(
      (res:any) =>{
        this.buscaPedidos(true);
      },
      (err:any)=>{

      }
    );

  }

  transferenciaParcial(){
    const tab = this.tabs.find((elem) => elem.status == "PENDENTE");
    const pedidos:PedidoTransferencia[] = tab.pedidos;
    const ids = pedidos.filter( x => x.Check).map(x=>x.ChaveNfe);

    this.apiTransferenciaService
    .TransferirParcial(
      true,
      this.from,
      this.to,
      ids
    ).subscribe(
      (res:any) =>{
        this.buscaPedidos(true);
      },
      (err:any)=>{

      }
    );

  }

  buscarPedidos(tab: any, useLoading: boolean){
    const paginator = tab.paginator.value;
    if(tab.status=="PENDENTE"){
      this.apiTransferenciaService
      .buscaPedidos(
        useLoading,
        this.from,
        this.to
      )
      .subscribe(
        (res: any) => {
          this.createTabData(res, tab.status);        
        },
        (err: any) => {                  
        }
      );
    }

    if(tab.status=="TRANSFERIDO"){
      this.apiTransferenciaService
      .buscaPedidosTransferidos(
        useLoading,
        this.from,
        this.to,
        0,
        25
      )
      .subscribe(
        (res: any) => {
          this.createTabData(res, tab.status);        
        },
        (err: any) => {        
        }
      );
    }

  }
  
  private createTabs() {
    this.tabs = [
      {
        name: "Pedidos",
        status: "PENDENTE",
        ordenacao: EnumOrdenacao.descendente,
        count: 0,
        pedidos: [],
        permission: "wms.transferencia.listar",
        paginator: new BehaviorSubject<IPaginator>({
          isLastPage: false,
          page: 1,
          pageLength: 25,
          total: 0,
        }),
      },
      {
        name: "Transferidos",
        status: "TRANSFERIDO",
        ordenacao: EnumOrdenacao.descendente,
        count: 0,
        pedidos: [],
        permission: "wms.transferencia.listar",
        paginator: new BehaviorSubject<IPaginator>({
          isLastPage: false,
          page: 1,
          pageLength: 25,
          total: 0,
        }),
      }      
    
    ];
  }


}
