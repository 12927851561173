import { Component, OnInit, Input, ViewChild, ElementRef, forwardRef, Injector, Optional } from '@angular/core';
import { SelectOptionComponent } from '../select-option/select-option.component';
import { Subject, ReplaySubject } from 'rxjs';
import { ControlValueAccessorMixin } from 'src/app/shared/classes/control-value-accessor-mixin.class';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReadOnlyDirective } from 'src/app/shared/directives/readonly.directive';

@Component({
  selector: 'c-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent extends ControlValueAccessorMixin {

  @Input() label: string;
  @Input() placeholder: string;
  @Input() isMultiple = false;

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  isOptionsVisible = false;
  selectedOption: SelectOptionComponent;
  selectedOptions: Set<SelectOptionComponent>;
  description: string;
  $filter = new Subject();
  $writeValue = new ReplaySubject();
  searchValue: string;

  constructor(
    elementRef: ElementRef,
    injector: Injector,
    @Optional() readOnlyDirective?: ReadOnlyDirective
  ) {
    super(elementRef, injector, readOnlyDirective);
  }

  writeValue(value: any) {
    if (!Array.isArray(value) && value != undefined || Array.isArray(value) && value.length != 0) {
      super.writeValue(value);
      this.$writeValue.next(value);
    } else {
      this.reset();
    }
  }

  hasOption(data: SelectOptionComponent) {
    if (this.isMultiple && this.selectedOptions) {
      return this.selectedOptions.has(data);
    } else {
      return this.selectedOption == data;
    }
  }

  selectOption(data: SelectOptionComponent, propaggateChanges = true) {
    if (this.isMultiple) {
      this.selectMultipleOption(data);
    } else {
      this.selectSingleOption(data);
      this.closeOptions();
    }

    // Set value accessor
    if (propaggateChanges) {
      this.value = this.createOptions();
    }
  }

  showOptions() {
    if (!this.isDisabled && !this.isReadOnly) {
      this.isOptionsVisible = true;
      this.searchInput.nativeElement.focus();
      this.focus();
    }
  }

  closeOptions() {
    if (this.isOptionsVisible) {
      this.blur();
    }

    this.isOptionsVisible = false;
    this.clearFilter();
  }

  filter() {
    this.$filter.next(this.searchValue);
  }

  clearFilter() {
    this.searchValue = '';
    this.$filter.next();
  }

  private selectMultipleOption(data: SelectOptionComponent) {
    if (!this.selectedOptions) {
      this.selectedOptions = new Set();
    }

    // Check if options don't exists
    if (!this.selectedOptions.has(data)) {
      this.selectedOptions.add(data);
    } else {
      this.selectedOptions.delete(data);
    }

    // Update description
    let description = '';
    this.selectedOptions.forEach((elem) => {
      if (!description) {
        description = `${elem.label || elem.description}`;
      } else {
        description += `, ${elem.label || elem.description}`;
      }
    });

    this.description = description;
  }

  private selectSingleOption(data: SelectOptionComponent) {
    this.selectedOption = data;
    this.description = data.label || data.description;
  }

  private createOptions() {
    if (this.isMultiple) {
      const options = new Array();

      this.selectedOptions.forEach(elem => {
        options.push(elem.value);
      });

      return options;
    } else {
      return this.selectedOption.value;
    }
  }

  private reset() {
    this.value = undefined;
    this.selectedOption = undefined;
    this.description = undefined;

    if (this.selectedOptions) {
      this.selectedOptions.clear();
    }
  }

}


