import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth.service';
import { Pagamento } from 'src/app/lib/dynamic-form/models/api/pagamento/pagamento';
import {Historico} from 'src/app/lib/dynamic-form/models/api/pagamento/historico';
import { HttpParams } from '@angular/common/http';
import { EnumPagamentoStatus } from './enum-pagamento-status.enum';
import { EnumOrdenacao } from '../pedido/enum-ordenacao.enum';

@Injectable({
  providedIn: 'root'
})
export class ApiPagamentoService {

  private baseUrl = 'dashboard';
  private controller: ApiHttpClientService;

    constructor(
    private api: ApiHttpClientService,
    private authServ: AuthService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.pagamento)
      .useBaseUrl(this.baseUrl);
  }

  buscaPagamentos(useLoading : boolean,loja:string, status : EnumPagamentoStatus = EnumPagamentoStatus.CREATED,from:string,to:string ,skip: number = 0, take: number = 25, ordenacao : EnumOrdenacao = EnumOrdenacao.ascendente) {
    
    let request = this.controller;

    const params = new HttpParams()
          .append('status', status.toString())
          .append('skip', skip.toString())
          .append('take', take.toString())
          .append('loja', loja)
          .append('from',from)
          .append('to',to)
          .append('order', ordenacao.toString());
      
    if(useLoading){
      request = request.useLoading()
    }

    return request      
      .get<Pagamento[]>('pagamento', {params});
  }

  buscaHistorico(useLoading:boolean,id:string){
    let request = this.controller;

    if(useLoading){
      request = request.useLoading()
    }
    
    return request
    .get<Historico[]>('pagamento/history/'+id);
  }

  estornaCashback(useLoading : boolean,id:string,lojaCnpj:string, document : string,dataPagamento:Date,valor:Number ,pedido: string ) {
      
    let request = this.controller;

      
    if(useLoading){
      request = request.useLoading()
    }

    return request 
      .post<any>('pagamento/estornar-beneficio', {AriusId:id,Partner:"Arius",MerchantDocument:lojaCnpj,Document:document,SalesDate:dataPagamento,Value:valor,SalesDocument:pedido});
  }

  buscaLojas(){
    let request = this.controller;

    return request
      .unwrap()
      .get<string[]>('loja');
  }
}
