import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiPedidoService } from 'src/app/services/api/pedido/api-pedido.service';
import { Pedido } from 'src/app/lib/dynamic-form/models/api/pedido/pedido';
import { ToastType } from 'src/app/ui/toast/toast.model';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ConfirmDialogService } from 'src/app/ui/modal/confirm-dialog.service';
import { DanfeDialogService } from 'src/app/ui/modal/danfe-dialog.service';

@Component({
  selector: 'c-product-list-table',
  templateUrl: './product-list-table.component.html',
  styleUrls: ['./product-list-table.component.scss']
})
export class ProductListTableComponent implements OnInit {
  @Output() listUpdated = new EventEmitter();
  @Output() setFocus = new EventEmitter();
  @Input() columns: string[];
  @Input() dataSource: object[];

  cancelStatuses : string[] = ["cancel", "canceled"]

  constructor(private apiPedidoService : ApiPedidoService,
    private toastService : ToastService,
    private confirmDialogService : ConfirmDialogService,
    private danfeDialogService : DanfeDialogService
  ) {
    
  }

  ngOnInit() {    
  }

  visualizarDanfe(pedido : Pedido,etiqueta:boolean){
    
    pedido.Etiqueta = etiqueta;

    if(this.cancelStatuses.includes(pedido.PedidoVtex.Status)){
      this.toastService.toast("Pedido \""+pedido.OrderId+"-"+pedido.Id+"\" está CANCELADO na VTEX.", ToastType.error);
      return;
    }
    
    this.danfeDialogService.confirm("Pedido: "+pedido.OrderId+"-"+pedido.Id, pedido)
    .then((send) => {
      this.setFocus.emit();
    })
    .catch((err) => {console.log(err)})  
  }

  downloadDanfe(pedido : Pedido){
    
    if(this.cancelStatuses.includes(pedido.PedidoVtex.Status)){
      this.toastService.toast("Pedido \""+pedido.OrderId+"-"+pedido.Id+"\" está CANCELADO na VTEX.", ToastType.error);
      return;
    }
    
    this.apiPedidoService.buscaDanfePedido(true, pedido.OrderId, pedido.Id).subscribe((res : any) => {      
      if(res.Data != null){
        const downloadLink = document.createElement('a');
        downloadLink.href = "data:application/pdf;base64,"+res.Data.Pdf;
        downloadLink.download = `${pedido.OrderId}-${pedido.Id}-DANFE.pdf`;
        downloadLink.click();
        this.setFocus.emit();
      }else{        
        this.toastService.toast("DANFE em processo de emissão para o pedido \""+pedido.OrderId+"-"+pedido.Id+"\".", ToastType.error);
      }
    },
    (error : any) => {
      this.toastService.toast("DANFE em processo de emissão para o pedido \""+pedido.OrderId+"-"+pedido.Id+"\".", ToastType.error);
    });
  }

  downloadEtiquetaDanfe(pedido : Pedido){
    
    if(this.cancelStatuses.includes(pedido.PedidoVtex.Status)){
      this.toastService.toast("Pedido \""+pedido.OrderId+"-"+pedido.Id+"\" está CANCELADO na VTEX.", ToastType.error);
      return;
    }
    
    this.apiPedidoService.buscaEtiquetaDanfePedido(true, pedido.OrderId, pedido.Id).subscribe((res : any) => {      
      if(res.Data != null){
        const downloadLink = document.createElement('a');
        downloadLink.href = "data:application/pdf;base64,"+res.Data.Pdf;
        downloadLink.download = `${pedido.OrderId}-${pedido.Id}-EtiquetaDANFE.pdf`;
        downloadLink.click();
        this.setFocus.emit();
      }else{        
        this.toastService.toast("DANFE em processo de emissão para o pedido \""+pedido.OrderId+"-"+pedido.Id+"\".", ToastType.error);
      }
    },
    (error : any) => {
      this.toastService.toast("DANFE em processo de emissão para o pedido \""+pedido.OrderId+"-"+pedido.Id+"\".", ToastType.error);
    });
  }

  confirmarQuantidade(pedido : Pedido){
    
    if(this.cancelStatuses.includes(pedido.PedidoVtex.Status)){
      this.toastService.toast("Pedido \""+pedido.OrderId+"-"+pedido.Id+"\" está CANCELADO na VTEX.", ToastType.error);
      return;
    }
    
    this.confirmDialogService.confirm("Pedido: "+pedido.OrderId+"-"+pedido.Id, pedido)
    .then((send) => {
      if(send){        
          this.listUpdated.emit();
      }
      this.setFocus.emit();
    })
    .catch((err) => {console.log(err)})
  }
}
