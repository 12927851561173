import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotaServico } from 'src/app/lib/dynamic-form/models/api/notaservico/notaservico';
import { ApiNotaServicoService } from 'src/app/services/api/notaservico/api-notaservico.service';
import { getLocalStorage } from 'src/app/shared/decorators/local-storage.decorator';
import { IConfiguration } from 'src/app/models/session/configuration.interface';
import { ToastService } from '../../toast/toast.service';
import { ToastType } from '../../toast/toast.model';
import { HttpClient } from '@angular/common/http';
import { LoadingService } from 'src/app/services/loading.service';


@Component({
  selector: 'c-notaservico-dialog',
  templateUrl: './notaservico-dialog.component.html',
  styleUrls: ['./notaservico-dialog.component.scss']
})
export class NotaservicoDialogComponent implements OnInit, OnDestroy {
  @Output() confirm: EventEmitter<boolean> = new EventEmitter();
  @Input() message: string;
  @Input() notaservico: NotaServico;
  private subscription: Subscription;

  readonly destroy: () => void;
  readonly beforeDestroy = (from: 'container' | 'content' | 'outside') => from != 'content' && this.confirm.next(false);

  
  notaStatus = -1;
  notaEmbed = "";
  tabSelected = 0;
  carregandoEtiquetas = false;

  @ViewChild('etiquetaIframe', { static: false }) iframe: ElementRef;

  constructor(private fb: FormBuilder, 
    private apiNotaServicoService : ApiNotaServicoService,
    private toastService : ToastService, 
    private loadingService : LoadingService,
    private httpClient : HttpClient) { }  

    ngOnInit() {
      this.subscription = this.confirm.pipe(
        first()
      ).subscribe(() => {
        this.destroy();
      });

      this.apiNotaServicoService.buscaDanfeNota(false, this.notaservico.Id).subscribe((res : any,) => {
        this.notaEmbed = "data:application/pdf;base64," + res.Data;
        this.notaStatus = 1;
 
        
    },
    (error : any) => {
      this.notaStatus = 0;
    });
    

    }

    exibirNota(){    
      if(this.notaEmbed == ""){
        return;
      }

      this.tabSelected = 0;
  }

    ngOnDestroy() {
      this.subscription.unsubscribe();
    }
}
