import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Pedido } from 'src/app/lib/dynamic-form/models/api/pedido/pedido';
import { ApiPedidoService } from 'src/app/services/api/pedido/api-pedido.service';
import { IConfiguration } from 'src/app/models/session/configuration.interface';
import { SessionService } from 'src/app/services/session.service';
import { setLocalStorage, getLocalStorage } from 'src/app/shared/decorators/local-storage.decorator';

@Component({
  selector: 'c-configuration-dialog',
  templateUrl: './configuration-dialog.component.html',
  styleUrls: ['./configuration-dialog.component.scss']
})
export class ConfigurationDialogComponent implements OnInit, OnDestroy {
  @Output() confirm: EventEmitter<boolean> = new EventEmitter();
  @Input() message: string;
  @Input() pedido: Pedido;
  private subscription: Subscription;
  form: FormGroup;
  configuration: IConfiguration;

  readonly destroy: () => void;
  readonly beforeDestroy = (from: 'container' | 'content' | 'outside') => from != 'content' && this.confirm.next(false);

  constructor(private fb: FormBuilder, private apiPedidoService : ApiPedidoService, private sessionService : SessionService) { }  

  ngOnInit() {
    this.subscription = this.confirm.pipe(
      first()
    ).subscribe(() => {
      this.destroy();
    });

    this.configuration = getLocalStorage("configuration") as IConfiguration

    this.form = this.fb.group({
      ip: [this.configuration != null ? this.configuration.ip : null, [Validators.required, Validators.pattern(/[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}/g)]],
      porta: [this.configuration != null? this.configuration.port : null, [Validators.required]]
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  salvarConfiguracoes(){    
    this.configuration = {
      ip : this.form.get("ip").value,
      port : this.form.get("porta").value
    }

    setLocalStorage("configuration",this.configuration);

    this.confirm.emit(true);
  }
  
}
