import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { IPaginator } from 'src/app/ui/paginator/paginator.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { IResponse } from 'src/app/models/api/response.interface';
import { skip, debounceTime } from 'rxjs/operators';
import { ApiPedidoService } from 'src/app/services/api/pedido/api-pedido.service';
import { Pedido } from 'src/app/lib/dynamic-form/models/api/pedido/pedido';
import { InputComponent } from 'src/app/ui/input/input/input.component';
import { EnumTabStatus } from 'src/app/services/api/pedido/enum-tab-status.enum';
import { ConfirmDialogService } from 'src/app/ui/modal/confirm-dialog.service';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ToastType } from 'src/app/ui/toast/toast.model';
import { DanfeDialogService } from 'src/app/ui/modal/danfe-dialog.service';
import { EnumOrdenacao } from 'src/app/services/api/pedido/enum-ordenacao.enum';
import { ApiJobsService } from 'src/app/services/api/jobs/api-jobs.service';

@Component({
  selector: 'c-label-list',
  templateUrl: './label-list.component.html',
  styleUrls: ['./label-list.component.scss']
})
export class LabelListComponent implements AfterViewInit {

  orders = []
  count = 0;

  leituraPedido$ = new BehaviorSubject('');
  pedidoParaAbrir: Subject<string> = new Subject();

  @ViewChild(InputComponent, {static: true}) searchField: InputComponent;

  regexNumeroPedido = /v[0-9]+r[i]?hp-[0-9]{2}/g

  columnFilters$ = new BehaviorSubject<any>([0]);
  columnFilters = ['id'];

  defaultTableColumns: string[] = ['id', 'nome', 'data', 'quantidade', 'quantidadeErros', 'acoes'];

  interval : any;

  tabs!: {
    name: string;
    status: string;
    ordenacao: EnumOrdenacao;
    count: number;
    orders: Pedido[];
    permission: string;
    paginator: BehaviorSubject<IPaginator>
  }[];

  constructor(private apiJobsService : ApiJobsService,
    private toastService : ToastService
  ) {
    this.createTabs();
  }

  ngAfterViewInit() {
    this.buscaPedidosPendentes(true);
    this.iniciaTimerRefresh();
  }

  reload(paginator: IPaginator, tab : any) {
    tab.paginator.next(paginator);
    this.buscaPedidos(tab, true);
  }

  iniciaTimerRefresh() {
    if(this.interval){
      clearInterval(this.interval);
    }

    this.interval = setTimeout(() => {
      this.buscaPedidosPendentes(false);
    }, 5000);
  }

  ngOnDestroy(){
    if(this.interval){
      clearInterval(this.interval);
    }
  }

  buscaPedidosPendentes(useLoading : boolean) {    
    for(let tab of this.tabs){
      this.buscaPedidos(tab, useLoading)
    }
  }

  buscaPedidos(tab : any, useLoading : boolean){
    const paginator = tab.paginator.value;

    let skip = (paginator.page - 1) * paginator.pageLength;
    let take = paginator.pageLength;
    
    this.apiJobsService.buscaJobsEtiqueta(useLoading, tab.status, skip, take).subscribe((res : any) => {      
      this.createTabData(res, tab.status);
      this.iniciaTimerRefresh()
    }, (err : any) => {
      this.iniciaTimerRefresh()
    });
  }  

  createTabData(data: any, status: string) {
    const tab = this.tabs.find(elem => elem.status == status);

    if (tab) {      
      tab.orders = data.Data;
      tab.count = data.Count;
      console.log(data);
    }
  }

  private createTabs() {
    this.tabs = [     
      {
        name: 'pendente',
        status: "pendente",
        ordenacao: EnumOrdenacao.descendente,
        count: 0,
        orders: [],
        permission: 'wms.pedido.listarFaturados',
        paginator : new BehaviorSubject<IPaginator>({
          isLastPage: false,
          page: 1,
          pageLength: 25,
          total: 0
        })
      },
      {
        name: 'processando',
        status: "processando",
        ordenacao: EnumOrdenacao.ascendente,
        count: 0,
        orders: [],
        permission: 'wms.pedido.listarPendentes',
        paginator : new BehaviorSubject<IPaginator>({
          isLastPage: false,
          page: 1,
          pageLength: 25,
          total: 0
        })
      },
      {
        name: 'erro',
        status: "erro",
        ordenacao: EnumOrdenacao.descendente,
        count: 0,
        orders: [],
        permission: 'wms.pedido.listarFaturados',
        paginator : new BehaviorSubject<IPaginator>({
          isLastPage: false,
          page: 1,
          pageLength: 25,
          total: 0
        })
      },
      {
        name: 'processado',
        status: "processado",
        ordenacao: EnumOrdenacao.descendente,
        count: 0,
        orders: [],
        permission: 'wms.pedido.listarFaturados',
        paginator : new BehaviorSubject<IPaginator>({
          isLastPage: false,
          page: 1,
          pageLength: 25,
          total: 0
        })
      }
    ];
  }
}
