import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth.service';
import { Pedido } from 'src/app/lib/dynamic-form/models/api/pedido/pedido';
import { HttpParams } from '@angular/common/http';
import { DanfePedido } from 'src/app/lib/dynamic-form/models/api/pedido/danfe-pedido';
import { JobEtiqueta } from 'src/app/models/api/job-etiqueta.interface';
import { ArquivoEtiqueta } from 'src/app/models/api/arquivo-etiqueta.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiJobsService {

  private baseUrl = 'Jobs';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService,
    private authServ: AuthService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.jobs)
      .useBaseUrl(this.baseUrl);
  }

  buscaJobsEtiqueta(useLoading : boolean, status : string, skip: number = 0, take: number = 25) {
    
    let request = this.controller;

    const params = new HttpParams()
          .append('status', status.toString())
          .append('skip', skip.toString())
          .append('take', take.toString())
      
    if(useLoading){
      request = request.useLoading()
    }

    return request
      .unwrap()
      .get<JobEtiqueta[]>('JobEtiqueta', {params});
  }

  enviaPlanilha(formData, useLoading: boolean = false){
    
    let request = this.controller;

    if(useLoading){
      request = request.useLoading()
    }
    
    return request.post<any>("JobEtiqueta", formData, {  
      reportProgress: true,  
      observe: 'events'  
    });  
  }

  buscaPdf(useLoading : boolean, id: string) {
    
    let request = this.controller;

    if(useLoading){
      request = request.useLoading()
    }

    return request
      .unwrap()
      .get<ArquivoEtiqueta>('JobEtiqueta/'+id+"/DownloadArquivo");
  }

  buscaErros(useLoading : boolean, id: string) {
    
    let request = this.controller;

    if(useLoading){
      request = request.useLoading()
    }

    return request
      .unwrap()
      .get<ArquivoEtiqueta>('JobEtiqueta/'+id+"/Erros");
  }
}
