import { Component, OnInit, Input, HostListener, OnDestroy, HostBinding } from '@angular/core';
import { SelectComponent } from '../select/select.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'c-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss']
})
export class SelectOptionComponent implements OnInit, OnDestroy {
  @HostBinding('attr.tabindex') tabindex = '0';
  @Input() value: any;
  @Input() description: string;
  @Input() label: string;
  @Input() selected = false;

  self = this;
  $filterSubscription: Subscription;
  $writeValue: Subscription;
  hasFilter = false;
  isFilterHasMatch = false;

  constructor(
    public selectComponent: SelectComponent
  ) { }

  ngOnInit() {
    if (this.selected) {
      this.selectComponent.selectOption(this);
    }

    this.listenFilter();
    this.listenWriteValue();
  }

  ngOnDestroy() {
    this.deleteSubscriptions();
  }

  @HostListener('click', ['$event'])
  @HostListener('keyup.enter', ['$event'])
  toggle(e: Event) {
    e.stopPropagation();
    this.selectComponent.selectOption(this);
  }

  private listenWriteValue() {
    this.$writeValue = this.selectComponent.$writeValue.subscribe((res: any) => {
      this.writeValue(res);
    });
  }

  private listenFilter() {
    this.$filterSubscription = this.selectComponent.$filter.subscribe((res: string) => {
      this.filter(res);
    });
  }

  private deleteSubscriptions() {
    this.$filterSubscription.unsubscribe();
    this.$writeValue.unsubscribe();
  }

  private writeValue(data: any) {
    if (this.selectComponent.isMultiple && Array.isArray(data)) {
      const option = data.find((elem: string) => {
        return elem === this.value;
      });

      if (option) {
        this.selectComponent.selectOption(this, false);
      }
    } else if (data === this.value) {
      this.selectComponent.selectOption(this, false);
    }
  }

  private filter(value: string) {
    this.isFilterHasMatch = false;

    if (value) {
      this.hasFilter = true;
      const description = '' + this.description;
      this.isFilterHasMatch = (new RegExp(value, 'i')).test(description.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
    } else {
      this.hasFilter = false;
    }
  }

}
