export interface IResponse<T = any> {
  data?: T;
  count?: number;
  message?: string;
}

export function isResponse(response: any): response is IResponse {
  try {
    return response
      && (response.message ? typeof response.message === 'string' : true)
      && (response.count ? typeof response.count === 'number' : true);
  } catch {
    return false;
  }
}
