import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { TabModule } from 'src/app/ui/tab/tab.module';
import { CardModule } from 'src/app/ui/card/card.module';
import { PageTitleModule } from 'src/app/ui/page-title/page-title.module';
import { IconModule } from 'src/app/ui/icon/icon.module';
import { PaginatorModule } from 'src/app/ui/paginator/paginator.module';
import { InputModule } from 'src/app/ui/input/input.module';
import { EmptyPatternModule } from 'src/app/ui/empty-pattern/empty-pattern.module';

// tslint:disable-next-line: max-line-length
import { CollapseModule } from 'src/app/ui/collapse/collapse.module';
import { DashboardComponent } from './dashboard/dashboard.component';
// tslint:disable-next-line: max-line-length
import { CheckboxModule } from 'src/app/ui/checkbox/checkbox.module';
import { SelectModule } from 'src/app/ui/select/select.module';
import { ColumnFilterModule } from 'src/app/ui/column-filter/column-filter.module';
import { TableSortModule } from 'src/app/ui/table-sort/table-sort.module';
import { ModalModule } from 'src/app/ui/modal/modal.module';
import { EtiquetaLojasRoutingModule } from './etiqueta-lojas-routing.module';
import { LabelListTableComponent } from './label-list/label-list-table/label-list-table.component';
import { LabelListComponent } from './label-list/label-list.component';
import { UploadFileComponent } from 'src/app/lib/upload-file/upload-file.component';

@NgModule({
  declarations: [
    LabelListComponent,
    LabelListTableComponent,
    DashboardComponent,
    UploadFileComponent
  ],
  imports: [
    CommonModule,
    EtiquetaLojasRoutingModule,
    SharedModule,
    TabModule,
    CardModule,
    PageTitleModule,
    IconModule,
    PaginatorModule,
    InputModule,
    EmptyPatternModule,
    CollapseModule,
    CheckboxModule,
    SelectModule,
    ColumnFilterModule,
    TableSortModule,
    ModalModule    
  ]
})
export class EtiquetaLojasModule { }
