import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth.service';
import { HttpParams,HttpHeaders } from '@angular/common/http';
import { Relatorio } from 'src/app/lib/dynamic-form/models/api/relatorio-preco/relatorio';

@Injectable({
    providedIn: 'root'
  })
  export class ApiRelatorioPrecoService {
  
    private baseUrl = '';
    private controller: ApiHttpClientService;
  
      constructor(
      private api: ApiHttpClientService,
      private authServ: AuthService
    ) {
      this.controller = this.api
        .useBaseUrl(environment.microservice.relatorioPrecos)
        .useBaseUrl(this.baseUrl);
    }

    gerarRelatorioPrecoFixos(useLoading:boolean,politicas:string){
      let request = this.controller;

      
      if(useLoading){
        request = request.useLoading()
      }

      let params = new HttpParams();
      if(!!politicas){
        params = params.append("politicas",politicas);
      }

      return request
              .get("/report/fixed-prices",{params})
    }

    gerarRelatorioPrecoCalculados(useLoading:boolean,politicas:string){
      let request = this.controller;

      
      if(useLoading){
        request = request.useLoading()
      }

      let params = new HttpParams();
      if(!!politicas){
        params = params.append("politicas",politicas);
      }

      return request
              .get("/report/computed-prices",{params})
    }

    obterRelatorios(useLoading:boolean){
      let request = this.controller;

      
      if(useLoading){
        request = request.useLoading()
      }

      let params = new HttpParams();

        params = params.append("skip","0");
        params = params.append("take","50");

      return request
              .get<Relatorio[]>("/report",{params})
    }

    obterRelatorio(useLoading:boolean,id:String){
      let request = this.controller;

      
      if(useLoading){
        request = request.useLoading()
      }

      return request
              .get<Relatorio>("/report/"+id)
    }

}