import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth.service';
import { HttpParams,HttpHeaders } from '@angular/common/http';
import { Seller } from 'src/app/lib/dynamic-form/models/api/manutencao-loja/seller';



@Injectable({
  providedIn: 'root'
})
export class ApiManutencaoLojaService {

  private baseUrl = '';
  private controller: ApiHttpClientService;

    constructor(
    private api: ApiHttpClientService,
    private authServ: AuthService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.manutencaoLoja)
      .useBaseUrl(this.baseUrl);
  }

  obterLojas(useLoading : boolean, lojas:string[]) {
    
    let request = this.controller;
                
    if(useLoading){
      request = request.useLoading()
    }

    return request      
      .unwrap()
      .post<Seller[]>(`/dock`,lojas);
  }

 
  atualizarSeller(useLoading : boolean,sellers:Seller[]) {
    
    let request = this.controller;

    if(useLoading){
      request = request.useLoading()
    }


    return request 
      .unwrap()     
      .put<Seller[]> ('/dock',sellers);
  }


}
