import { Component, AfterViewInit } from '@angular/core';
import { Relatorio } from 'src/app/lib/dynamic-form/models/api/relatorio-preco/relatorio';
import { ApiRelatorioPrecoService } from 'src/app/services/api/relatorio-preco/api-relatorio-preco.service';
import { ToastService} from 'src/app/ui/toast/toast.service';
import { ToastType } from 'src/app/ui/toast/toast.model';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'c-relatorio-list',
  templateUrl: './relatorio-list.component.html',
  styleUrls: ['./relatorio-list.component.scss']
})
export class RelatorioListComponent implements AfterViewInit {
  tipoPrecos: string[] = ["Fixos","Calculados"];
  politicas:any[] =[
    {"Codigo":0 , "Dsc":"Todas"},
    {"Codigo":1 , "Dsc":"1 - RiHappy"},
    {"Codigo":2 , "Dsc":"2 - PbKids"},
    {"Codigo":3 , "Dsc":"3- Motorola"},
    {"Codigo":4 , "Dsc":"4 - Riachuelo"},
    {"Codigo":5 , "Dsc":"5 - Magazine Luiza"},
    {"Codigo":6 , "Dsc":"6 - Festalab"},
    {"Codigo":7 , "Dsc":"7 - Banco Inter"},
    {"Codigo":8 , "Dsc":"8 - B2W"},
    {"Codigo":9 , "Dsc":"9 - On Store"},
    {"Codigo":10 , "Dsc":"10 - Google Shopping"},
    {"Codigo":11, "Dsc":"11 - Mercado Livre"},
    {"Codigo":12 , "Dsc":"12 - Banco Next"},
    {"Codigo":13 , "Dsc":"13 - LTM Vertem"},
    {"Codigo":14 , "Dsc":"14 - Instore"},
    {"Codigo":15 , "Dsc":"15 - Smiles"},
    {"Codigo":16 , "Dsc":"16 - Carrinho Misto"},    
    {"Codigo":17 , "Dsc":"17 - Super app"}
  ]
  tipoPreco:string;
  politica:number[];
  relatorios: Relatorio[]=[];

  constructor( private apiRelatorioPrecoService:ApiRelatorioPrecoService,
              private toastService:ToastService) { 
                this.obterRelatorios();

              }

  ngAfterViewInit() {

  }

  verificarRelatorio(relatorio:Relatorio){
    this.apiRelatorioPrecoService.obterRelatorio(false,relatorio.Id)
    .subscribe(data=>{
      relatorio.Status=data.Status;
      relatorio.Filename=data.Filename;
      relatorio.UpdatedSkus = data.UpdatedSkus;
      relatorio.TotalSkus = data.TotalSkus;
      if(relatorio.Status!="Complete"){
        setTimeout(()=>{this.verificarRelatorio(relatorio)},3000);
      }  

    });
  }

  obterRelatorios(){
    this.apiRelatorioPrecoService.obterRelatorios(true)
    .subscribe(data=>{
        this.relatorios = data;
        this.relatorios.forEach((r)=>{
          if(r.Status!="Complete"){
            this.verificarRelatorio(r);
          }
        });
    },
    (err)=>{
      this.toastService.toast("Erro ao obter os relatorios",ToastType.error);
    });
  }

  downloadFile(id:String){    
    this.toastService.toast("Download concluido ", ToastType.success);
    
      const url= environment.api + environment.microservice.relatorioPrecos+"/report/download/"+id
      window.location.href=url; 
  }

  gerarRelatorio(){
    if(!this.tipoPreco){
      this.toastService.toast("Precisa escolher o tipo de relatorio", ToastType.error);
      return;      
    }
    if(this.politica.length>1){
      let idx = this.politica.indexOf(0);
      if(idx>=0){
        this.politica.splice(idx,1);
      }
    }

    let politicas = this.politica.join(',');
    if(politicas=="0"){
      politicas="";
    }

    if(this.tipoPreco == "Fixos"){
      this.apiRelatorioPrecoService.gerarRelatorioPrecoFixos(true,politicas)
      .subscribe(data=>{
        setTimeout(()=>{this.obterRelatorios();},1000);
      });
    }

    if(this.tipoPreco == "Calculados"){
      this.apiRelatorioPrecoService.gerarRelatorioPrecoCalculados(true,politicas)
      .subscribe(data=>{
        setTimeout(()=>{this.obterRelatorios();},1000);
      });
    }
  }

}
