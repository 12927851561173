import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';  
import { catchError, map } from 'rxjs/operators';  
import { DataService } from 'src/app/services/data.service';
import { ApiJobsService } from 'src/app/services/api/jobs/api-jobs.service';

@Component({
  selector: 'c-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef;
  arquivo:object  = {data:null,inProgress: false, progress: 0};
  @Output() uploadComplete = new EventEmitter();

  constructor(private apiJobsService: ApiJobsService) { }

  ngOnInit() {
  }

  uploadFile(file) {  
    const formData = new FormData();  
    formData.append('file', file.data);  
    file.inProgress = true;  
    this.apiJobsService.enviaPlanilha(formData, true).pipe(  
      map(event => {  
        switch (event.type) {  
          case HttpEventType.UploadProgress:  
            file.progress = Math.round(event.loaded * 100 / event.total);  
            break;  
          case HttpEventType.Response:  
            return event;  
        }  
      }),  
      catchError((error: HttpErrorResponse) => {  
        file.inProgress = false;  
        return of(`${file.data.name} upload failed.`);  
      })).subscribe((event: any) => {  
        if (typeof (event) === 'object') {  
          console.log(event.body);
          this.uploadComplete.emit();
        }
      });  
  }

  validateAndUploadSpreadSheet(file) {    
    this.arquivo = { data:file,inProgress: false, progress: 0}
    this.uploadFile(this.arquivo);          
  }

}
