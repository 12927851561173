import { Injectable } from '@angular/core';
import { AbstractHttpService } from './http.service';
import { HttpHandler, HttpResponse, HttpClient } from '@angular/common/http';
import { SessionService } from './session.service';
import { map, finalize } from 'rxjs/operators';
import { IResponse, isResponse } from '../models/api/response.interface';
import { LoadingService, LoadingName } from './loading.service';
import { ToastService } from '../ui/toast/toast.service';
import { ToastType } from '../ui/toast/toast.model';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root',
  deps: [
    HttpHandler,
    SessionService,
    LoadingService,
    ToastService,
    AuthService
  ],
  useFactory: (
    httpHandler: HttpHandler,
    sessionService: SessionService,
    loadingService: LoadingService,
    toastService: ToastService,
    authService: AuthService,
  ) => {
    return (new ApiHttpIdmService(
      httpHandler,
      sessionService,
      loadingService,
      toastService,
      authService
    ))      
      .useAuth()
      .useBaseUrl(environment.api);
  }
})
export class ApiHttpIdmService extends AbstractHttpService {

  private constructor(
    httpHandler: HttpHandler,
    private sessionService: SessionService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private authService: AuthService
  ) {
    super(httpHandler);
  }

  instanceFactory(handler: HttpHandler) {
    return new ApiHttpIdmService(
      handler,
      this.sessionService,
      this.loadingService,
      this.toastService,
      this.authService
    ) as this;
  }

  unwrap(ref = 'unwrap') {
    return this.use((req, next) => {
      return next.handle(req).pipe(
        map(event => {
          if (event instanceof HttpResponse) {
            if (isResponse(event.body)) {
              return event.clone<IResponse<any>>({
                body: event.body.data
              });
            } else {
              throw new Error('O objeto retornado pela api não é do tipo IResponse');
            }
          }
        })
      );
    }, ref);
  }

  useAuth(ref = 'auth') {
    return this.use((req, next) => {
      const session = this.sessionService.getSession();

      if (session && session.accessToken) {
        return next.handle(
          req.clone({
            setHeaders: { Authorization: `Bearer ${session.accessToken}` },
          })
        );
      }

      console.warn('Token não foi adicionado pois usuário esta sem sessao', session);
      return next.handle(req);
    }, ref);
  }

  useToast(ref = 'toast') {
    return this.useSideEffect({
      next: event => {
        if (event instanceof HttpResponse) {
          if (isResponse(event.body)) {
            if (event.body.message) { this.toastService.toast(event.body.message); }
          } else {
            console.warn('O objeto retornado pela api não é do tipo IResponse');
          }
        }
      },
      error: error => {
        console.error('erro na resposta da api', error);
        if (error && isResponse(error.error) && error.error.message) {
          this.toastService.toast(error.error.message, ToastType.error);
        } else {
          this.toastService.toast('Ocorreu erro na requisição!', ToastType.error);
        }
      }
    }, () => {}, ref);
  }

  useLoading(name?: LoadingName, delay?: number, ref = 'loading') {
    return this.use((req, next) => {
      const loadingRef = this.loadingService.show(name, delay);

      return next.handle(req).pipe(
        finalize(() => loadingRef && loadingRef.dismiss())
      );
    }, ref);
  }

  cancelRequestWithoutPermission(permission: string, ref = 'cancelRequest') {
    return this.use((req, next) => {
      if (this.authService.hasPermission(permission)) {
        return next.handle(req);
      } else {
        console.warn(`A URL '${req.url}' de requisição foi bloqueada pela falta de permissão`, req);
        return EMPTY;
      }
    }, ref);
  }
}
