import { Component, OnInit, Input, Output, EventEmitter , ViewChild,TemplateRef} from '@angular/core';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { PedidoTransferencia } from 'src/app/lib/dynamic-form/models/api/transferencia/pedidoTransferencia';
import { ApiTransferenciaServicoService } from "src/app/services/api/transferencia/api-transferencia.service";
import { ToastType } from 'src/app/ui/toast/toast.model';
import { ModalService } from 'src/app/ui/modal/modal.service';

@Component({
  selector: 'c-transferencia-list-table',
  templateUrl: './transferencia-list-table.component.html',
  styleUrls: ['./transferencia-list-table.component.scss']
})
export class TransferenciaListTableComponent implements OnInit {
  @ViewChild('tplItems',{static:false}) tplItems: TemplateRef<any>;
  @Output() listUpdated = new EventEmitter();
  @Output() setFocus = new EventEmitter();
  @Input() columns: string[];
  @Input() dataSource: object[];  

  
  @Output() sortEvent = new EventEmitter<{ [x: string]: number }>();

  items :any[] = [];

  constructor(private toastService : ToastService,
    private apiTransferenciaService:ApiTransferenciaServicoService,
    private modalService:ModalService) { }

  ngOnInit() {
  }

  updateSort(data: { [x: string]: number }) {    
    this.sortEvent.emit(data);
  }
  
  visualizarItems(pedido:PedidoTransferencia){
    this.items= pedido.Items;
    this.modalService.open(this.tplItems);
  }

}
