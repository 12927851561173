import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth.service';
import { Pedido } from 'src/app/lib/dynamic-form/models/api/pedido/pedido';
import { HttpParams } from '@angular/common/http';
import { EnumTabStatus } from './enum-tab-status.enum';
import { DanfePedido } from 'src/app/lib/dynamic-form/models/api/pedido/danfe-pedido';
import { EnumOrdenacao } from './enum-ordenacao.enum';
import { Etiqueta } from 'src/app/lib/dynamic-form/models/api/pedido/etiqueta';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiPedidoService {

  private baseUrl = 'OmsConnector';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService,
    private authServ: AuthService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.oms)
      .useBaseUrl(this.baseUrl);
  }

  buscaPedidosPendentes(useLoading : boolean, status : EnumTabStatus = EnumTabStatus.pendente, skip: number = 0, take: number = 25, ordenacao : EnumOrdenacao = EnumOrdenacao.ascendente) {
    
    let request = this.controller;

    const params = new HttpParams()
          .append('status', status.toString())
          .append('skip', skip.toString())
          .append('take', take.toString())
          .append('centro', "1950")
          .append('ordenacao', ordenacao.toString());
      
    if(useLoading){
      request = request.useLoading()
    }

    return request
      .unwrap()
      .get<Pedido[]>('BuscaPedidos', {params});
  }

  buscaPedido(useLoading : boolean, orderId: string, fulfillmentId: string) {
    
    let request = this.controller;

    const params = new HttpParams()
          .append('OrderId', orderId)
          .append('Id', fulfillmentId);
      
    if(useLoading){
      request = request.useLoading()
    }

    return request
      .unwrap()
      .get<Pedido[]>('BuscaPedido', {params});
  }

  buscaDanfePedido(useLoading : boolean, orderId: string, fulfillmentId: string) {
    
    let request = this.controller;

    const params = new HttpParams()
          .append('OrderId', orderId)
          .append('Id', fulfillmentId);
      
    if(useLoading){
      request = request.useLoading()
    }


    return request
      .unwrap()
      .get<DanfePedido>('BuscaDanfePedido', {params});
  }

  buscaEtiquetaDanfePedido(useLoading:boolean , orderId: string,fulfillmentId: string){
    let request = this.controller;

    const params = new HttpParams()
          .append('OrderId', orderId)
          .append('Id', fulfillmentId);
      
    if(useLoading){
      request = request.useLoading()
    }


    return request
      .unwrap()
      .get<Etiqueta>('GeraEtiquetaNF', {params});
  }


  buscaZplEtiquetaDanfePedido(useLoading:boolean , orderId: string,fulfillmentId: string){
    let request = this.controller;

    const params = new HttpParams()
          .append('OrderId', orderId)
          .append('Id', fulfillmentId);
      
    if(useLoading){
      request = request.useLoading()
    }


    return request
      .unwrap()
      .get<String>('GeraZPLNF', {params});
  }

  buscaZplEtiquetaCorreioPedido(useLoading:boolean , orderId: string,fulfillmentId: string){
    let request = this.controller;

    const params = new HttpParams()
          .append('OrderId', orderId)
          .append('Id', fulfillmentId);
      
    if(useLoading){
      request = request.useLoading()
    }


    return request
      .unwrap()
      .get<String>('correio/GeraZplEtiqueta', {params});
  }

  buscaZplEtiquetaMgzPedido(useLoading:boolean , orderId: string,fulfillmentId: string){
    let request = this.controller;

    const params = new HttpParams()
          .append('OrderId', orderId)
          .append('Id', fulfillmentId);
      
    if(useLoading){
      request = request.useLoading()
    }


    return request
      .unwrap()
      .get<String>('mgz/GeraZplEtiqueta', {params});
  }

  buscaImageEtiquetaDanfePedido(useLoading:boolean , orderId: string,fulfillmentId: string){
    let request = this.controller;

    const params = new HttpParams()
          .append('OrderId', orderId)
          .append('Id', fulfillmentId);
          
      
    if(useLoading){
      request = request.useLoading()
    }

    return request
      .get('GeraImageEtiquetaNF',{params,responseType:"blob",observe:"response"});
  }

  buscaImageEtiquetaCorreioPedido(useLoading:boolean , orderId: string,fulfillmentId: string){
    let request = this.controller;

    const params = new HttpParams()
          .append('OrderId', orderId)
          .append('Id', fulfillmentId);
          
      
    if(useLoading){
      request = request.useLoading()
    }

    return request
      .get('correio/GeraImageEtiqueta',{params,responseType:"blob",observe:"response"});
  }

  buscaImageEtiquetaMgzPedido(useLoading:boolean , orderId: string,fulfillmentId: string){
    let request = this.controller;

    const params = new HttpParams()
          .append('OrderId', orderId)
          .append('Id', fulfillmentId);
          
      
    if(useLoading){
      request = request.useLoading()
    }

    return request
      .get('mgz/GeraImageEtiqueta',{params,responseType:"blob",observe:"response"});
  }

  enviaQuantidadeVolumes(pedido : Pedido, quantidadeVolumes : number) {
    return this.controller
      .useLoading()
      .unwrap()
      .post('EnviaQuantidadeVolumes', {OrderId: pedido.OrderId, Id: pedido.Id, Quantidade: quantidadeVolumes});
  }
}
