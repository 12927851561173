import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiHttpOmniClient } from '../../api-http-omni-client.service';

@Injectable({
  providedIn: 'root'
})
export class ApiOmniService {

  private controller: ApiHttpOmniClient;

  constructor(
    private api: ApiHttpOmniClient,
  ) {
  this.controller = api;
      
  }

  buscarStatusPedido(pedido){
    return this.controller
      .useLoading()
      .unwrap()
      .post('StatusPedidos', pedido);
  }

  reenviarPedido(pedido){
    return this.controller
      .useLoading()
      .unwrap()
      .post('ProcessaPedido?forcar=true', pedido);
  }


  reenviarPedidos(lst){
    return this.controller
      .useLoading()
      .unwrap()
      .post('ProcessaPedidos?forcar=true', lst);
  }
}
