import { Injectable } from '@angular/core';
import { ModalService, ModalConfig } from './modal.service';
import { tap, take } from 'rxjs/operators';
import { AreYouSureDialog } from 'src/app/shared/directives/are-you-sure/are-you-sure-dialog.class';
import { NotaServico } from 'src/app/lib/dynamic-form/models/api/notaservico/notaservico';
import { NotaservicoDialogComponent } from './notaservico-dialog/notaservico-dialog.component';

@Injectable()
export class NotaServicoDialogService extends AreYouSureDialog {
  constructor(
    private modalService: ModalService
  ) {
    super();
  }

  confirm(message: string, notaservico : NotaServico, modalConfig: ModalConfig<any, NotaservicoDialogComponent> = {}) {
    return new Promise<boolean>((resolve) => {
      const ref = this.modalService.open(NotaservicoDialogComponent, {
        initialState: {
          type: 'large'
        },
        contentInitialState: {
          message,
          notaservico
        },
        ...modalConfig
      });

      ref.contentComponentRef.instance.confirm.pipe(take(1)).subscribe( v => {        
        resolve(v)
      });
    });

  }
}
