import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgxMaskModule } from 'ngx-mask';
import { PipesModule } from './pipes/pipes.module';
import { DirectivesModule } from './directives/directives.module';
import { CdkTableModule } from '@angular/cdk/table';
import { TooltipModule } from 'ng2-tooltip-directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    PipesModule,
    FilterPipeModule,
    NgxMaskModule.forChild(),
    DirectivesModule,
    CdkTableModule,
    TooltipModule,
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PipesModule,
    FilterPipeModule,
    NgxMaskModule,
    DirectivesModule,
    CdkTableModule,
    TooltipModule,
  ],
  providers: []
})
export class SharedModule { }
