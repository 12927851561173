import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { setLocalStorage, getLocalStorage } from 'src/app/shared/decorators/local-storage.decorator';

@Component({
  selector: 'c-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapseComponent implements OnInit {

  @Input() id: string;
  @Input() icon: string;
  @Input() title: string;

  // tslint:disable-next-line: variable-name
  private _open: boolean;

  constructor() {
  }

  ngOnInit() {
    this.open = getLocalStorage(`collapseComponent_${this.id}`);
  }

  toggle() {
    this.open = !this.open;
  }

  get open(): boolean {
    return this._open;
  }

  @Input()
  set open(value: boolean) {
    setLocalStorage(`collapseComponent_${this.id}`, value);
    this._open = value;
  }

}
