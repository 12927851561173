import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select/select.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SelectOptionComponent } from './select-option/select-option.component';
import { IconModule } from '../icon/icon.module';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { InputErrorModule } from '../input-error/input-error.module';

@NgModule({
  declarations: [
    SelectComponent,
    SelectOptionComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    IconModule,
    CheckboxModule,
    InputErrorModule,
  ],
  exports: [
    SelectComponent,
    SelectOptionComponent
  ]
})
export class SelectModule { }
