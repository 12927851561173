import { Injectable } from '@angular/core';
import { ApiHttpIdmService } from '../../api-http-idm.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth.service';
import { HttpHeaders,HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiAutenticacaoService {

  private baseUrl = 'protocol/openid-connect';
  private controller: ApiHttpIdmService;

  constructor(
    private api: ApiHttpIdmService,
    private authServ: AuthService
  ) {
    this.controller = this.api
      .useBaseUrl(this.baseUrl);      
  }

  auth(login: string, senha: string) {
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    let params = new HttpParams({
      fromObject:  {
        grant_type:'password',
        username:login,
        password:senha
      },
    });

    return this.controller
      .useLoading('login')      
      .post<any>(  'token',  params.toString(),options).pipe(tap((res) => {
        this.authServ.login(res.access_token, login);
      }));
  }

}
