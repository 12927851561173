// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  localStoragePrefix: 'rihappy_qa',
  permissionPrefix: 'wms',  
  api: 'https://wms-qa.gruporihappy.com.br',
  omniApi: 'https://localhost:44397/Pedido',
  Idm:{
    Url:'',
    ClientId:'',
    ClientSecret:''
  } ,
  microservice: {
    oms: 'wms/ConectorOms',
    jobs: 'jobs',
    autenticacao: 'https://wms.gruporihappy.com.br/api/Autenticacao',
    pagamento:'/payment',
    notaservico:'/nfservico',
    transferencia: '/proxy-ceva',
    pricefy: 'https://wms.gruporihappy.com.br/api/PriceFyQA',
	relatorioPrecos:'/reportprecovtex',
	manutencaoLoja:'/api-ship'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
