import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth.service';
import { PedidoTransferencia } from 'src/app/lib/dynamic-form/models/api/transferencia/pedidoTransferencia';
import { HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ApiTransferenciaServicoService {

  private baseUrl = '';
  private controller: ApiHttpClientService;

    constructor(
    private api: ApiHttpClientService,
    private authServ: AuthService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.transferencia)
      .useBaseUrl(this.baseUrl);
  }

  buscaPedidosTransferidos(useLoading : boolean, from: string,to:string ,skip: number = 0, take: number = 25) {
    
    let request = this.controller;

    let params = new HttpParams();

    if(from != undefined && from !=""){        
        params = params.append('dtInicio', from);   
    }
    if(to != undefined && to!=""){
        params = params.append('dtFim', to);    
    }
          
          params = params.append('skip',skip.toString())
          .append('take',take.toString());
          
      
    if(useLoading){
      request = request.useLoading()
    }

    return request      
      .get<PedidoTransferencia[]>('/transferencia/b2c/pedidos-transferidos', {params});
  }

 
  buscaPedidos(useLoading : boolean, emissaoInicio: string,emissaoFim) {
    
    let request = this.controller;

    let params = new HttpParams();

    params = params.append('dtEmissaoInicio',emissaoInicio);
    params = params.append('dtEmissaoFim',emissaoFim);

    if(useLoading){
      request = request.useLoading()
    }


    return request      
      .get<any> ('/transferencia/b2c/pedidos',{params});
  }


  Transferir(useLoading : boolean,emissaoInicio:String,emisaoFim:string) {
    let request = this.controller;

      
    if(useLoading){
      request = request.useLoading()
    }

    return request      
      .post('/transferencia/b2c/',{DataEmissaoInicio:emissaoInicio,DataEmissaoFim:emisaoFim});
  }

  TransferirParcial(useLoading : boolean,emissaoInicio:String,emissaoFim:string,chaves:string[]) {
    let request = this.controller;

      
    if(useLoading){
      request = request.useLoading()
    }

    return request      
      .post('/transferencia/b2c/parcial',{DataEmissaoInicio:emissaoInicio,DataEmissaoFim:emissaoFim,Chaves:chaves});
  }

}
