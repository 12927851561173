import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth.service';
import { NotaServico } from 'src/app/lib/dynamic-form/models/api/notaservico/notaservico';
import { Download } from 'src/app/lib/dynamic-form/models/api/notaservico/download';
import { HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ApiNotaServicoService {

  private baseUrl = 'dashboard';
  private controller: ApiHttpClientService;

    constructor(
    private api: ApiHttpClientService,
    private authServ: AuthService
  ) {
    this.controller = this.api
      .useBaseUrl(environment.microservice.notaservico)
      .useBaseUrl(this.baseUrl);
  }

  buscaNotas(useLoading : boolean,cnpj:string, nome : string ,codigo:string,nf:string ,empresa:string, from: string,to:string ,skip: number = 0, take: number = 25) {
    
    let request = this.controller;

    let params = new HttpParams();

    if(cnpj != undefined && cnpj!= "")
        params = params.append('cnpj', cnpj);

    if(nome != undefined && nome!="")
        params = params.append('nome', nome);
    if(codigo != undefined && codigo!="")
        params= params.append('codigo', codigo);
    if(nf != undefined && nf!="")
        params = params.append('nf', nf);
    if(from != undefined && from !=""){
        from = from.replace(/-/g,"");
        params = params.append('start', from);   
    }
    if(to != undefined && to!=""){
        to = to.replace(/-/g,"");
        params = params.append('end', to);    
    }

    if(empresa != undefined && empresa!= "")
    params = params.append('empresa', empresa);
          
          params = params.append('skip',skip.toString())
          .append('take',take.toString());
          
      
    if(useLoading){
      request = request.useLoading()
    }

    return request      
      .get<NotaServico[]>('/nfs/find', {params});
  }

 
  buscaDanfeNota(useLoading : boolean, Id: String) {
    
    let request = this.controller;


    if(useLoading){
      request = request.useLoading()
    }


    return request      
      .get<any> ('/nfs/'+Id+'/pdf/b64');
  }

  downloadNotasByFilter(useLoading : boolean,cnpj:string, nome : string ,codigo:string,nf:string ,empresa:string, from: string,to:string ) {
    let request = this.controller;

    let params = new HttpParams();

    if(cnpj != undefined && cnpj!= "")
        params = params.append('cnpj', cnpj);

    if(nome != undefined && nome!="")
        params = params.append('nome', nome);
    if(codigo != undefined && codigo!="")
        params= params.append('codigo', codigo);
    if(nf != undefined && nf!="")
        params = params.append('nf', nf);
    if(from != undefined && from !=""){
        from = from.replace(/-/g,"");
        params = params.append('start', from);   
    }
    if(to != undefined && to!=""){
        to = to.replace(/-/g,"");
        params = params.append('end', to);    
    }
    if(empresa != undefined && empresa!= "")
    params = params.append('empresa', empresa);
          
      
    if(useLoading){
      request = request.useLoading()
    }

    return request      
      .get<Download>('/nfs/find/download', {params});
  }

  downloadNotasById(useLoading : boolean,ids:String[]) {
    let request = this.controller;

      
    if(useLoading){
      request = request.useLoading()
    }

    return request      
      .post<Download>('/nfs/find/download',ids);
  }

  downloadInfo(useLoading : boolean,id:String) {
    let request = this.controller;

      
    if(useLoading){
      request = request.useLoading()
    }

    return request      
      .get<Download>('/nfs/download/info/'+id);    
  }

  download(useLoading : boolean,id:String) {
    let request = this.controller;

      
    if(useLoading){
      request = request.useLoading()
    }

    return request      
      .get<Download>('/nfs/download/'+id);    
  }

}
