import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpfPipe } from './cpf.pipe';
import { CnpjPipe } from './cnpj.pipe';
import { PhonePipe } from './phone.pipe';
import { RangeToArrayPipe } from './rangeToArray.pipe';
import { SafePipe } from './safe.pipe';

@NgModule({
  declarations: [
    CpfPipe,
    CnpjPipe,
    PhonePipe,
    RangeToArrayPipe,
    SafePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CpfPipe,
    CnpjPipe,
    PhonePipe,
    RangeToArrayPipe,
    SafePipe
  ]
})
export class PipesModule { }
