import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Pedido } from 'src/app/lib/dynamic-form/models/api/pedido/pedido';
import { ApiPedidoService } from 'src/app/services/api/pedido/api-pedido.service';
import { DanfePedido } from 'src/app/lib/dynamic-form/models/api/pedido/danfe-pedido';
import { ZebraService } from 'src/app/services/zebra.service';
import { getLocalStorage } from 'src/app/shared/decorators/local-storage.decorator';
import { IConfiguration } from 'src/app/models/session/configuration.interface';
import { ToastService } from '../../toast/toast.service';
import { ToastType } from '../../toast/toast.model';
import { HttpClient } from '@angular/common/http';
import { LoadingService } from 'src/app/services/loading.service';
import { resetFakeAsyncZone } from '@angular/core/testing';

declare const BrowserPrint:any;
declare const Zebra:any;

@Component({
  selector: 'c-danfe-dialog',
  templateUrl: './danfe-dialog.component.html',
  styleUrls: ['./danfe-dialog.component.scss']
})
export class DanfeDialogComponent implements OnInit, OnDestroy {
  @Output() confirm: EventEmitter<boolean> = new EventEmitter();
  @Input() message: string;
  @Input() pedido: Pedido;
  private subscription: Subscription;

  readonly destroy: () => void;
  readonly beforeDestroy = (from: 'container' | 'content' | 'outside') => from != 'content' && this.confirm.next(false);

  danfePedido : DanfePedido = null;
  danfeStatus = -1;
  danfeEmbed = "";
  etiquetaDanfeEmbed="";
  etiquetaEmbed = "";
  etiquetaZplEmbed = "";
  tabSelected = 0;
  carregandoEtiquetas = false;
  imageEtiquetaDanfeEmbed: any;
  etiquetaZpl = "";
  imprimindoEtiqueta = false;


  @ViewChild('etiquetaIframe', { static: false }) iframe: ElementRef;

  constructor(private fb: FormBuilder, 
    private apiPedidoService : ApiPedidoService,
    private zebraService : ZebraService, 
    private toastService : ToastService, 
    private loadingService : LoadingService,
    private httpClient : HttpClient) { }  

  defaultTableColumns: string[] = ['sku', 'nome', 'quantidade']; 

  ngOnInit() {
    this.subscription = this.confirm.pipe(
      first()
    ).subscribe(() => {
      this.destroy();
    });

    
    this.apiPedidoService.buscaDanfePedido(false, this.pedido.OrderId, this.pedido.Id).subscribe((res : any,) => {
        this.danfePedido = res.Data;
        this.danfeStatus = res.Count;
        if(this.danfePedido != null){
          this.danfeEmbed = "data:application/pdf;base64,"+this.danfePedido.Pdf;
        }   
        
    },
    (error : any) => {
      this.danfeStatus = 0;
    });
    
    
    if(this.pedido.Etiqueta){
      this.exibirEtiquetaDanfe();
    }
      
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  exibirDanfe(){    
      if(this.danfePedido == null){
        return;
      }

      this.tabSelected = 0;
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageEtiquetaDanfeEmbed = reader.result;
    }, false);
 
    if (image) {
       reader.readAsDataURL(image);
    }
  }

  exibirEtiquetaCorreio(){
    
      this.carregandoEtiquetas = true;
    this.apiPedidoService.buscaImageEtiquetaCorreioPedido(false, this.pedido.OrderId, this.pedido.Id).subscribe((res : any,) => {

      this.createImageFromBlob(res.body);
      this.carregandoEtiquetas = false;

      this.apiPedidoService.buscaZplEtiquetaCorreioPedido(false,this.pedido.OrderId,this.pedido.Id).subscribe((res:any,)=>{
        if(res.Data!=null && res.Data!=""){
        this.etiquetaZpl = res.Data;
        }
      },
      (error:any)=>{

      });

      },
      (error : any) => {
        this.carregandoEtiquetas = false;
      });
    
    this.tabSelected = 3;
  }

  exibirEtiquetaMgz(){
    
      this.carregandoEtiquetas = true;
    this.apiPedidoService.buscaImageEtiquetaMgzPedido(false, this.pedido.OrderId, this.pedido.Id).subscribe((res : any,) => {

      this.createImageFromBlob(res.body);
      this.carregandoEtiquetas = false;

      this.apiPedidoService.buscaZplEtiquetaMgzPedido(false,this.pedido.OrderId,this.pedido.Id).subscribe((res:any,)=>{
        if(res.Data!=null && res.Data!=""){
        this.etiquetaZpl = res.Data;
        }
      },
      (error:any)=>{

      });

      },
      (error : any) => {
        this.carregandoEtiquetas = false;
      });
    
    this.tabSelected = 4;
  }

  exibirEtiquetaDanfe(){

    
      this.carregandoEtiquetas = true;
    this.apiPedidoService.buscaImageEtiquetaDanfePedido(false, this.pedido.OrderId, this.pedido.Id).subscribe((res : any,) => {
      /*
      if(res.Data != null){
        this.etiquetaDanfeEmbed = "data:application/pdf;base64,"+res.Data.Pdf;
        this.carregandoEtiquetas = false;
      } 
      */
     
      this.createImageFromBlob(res.body);
      this.carregandoEtiquetas = false;

      this.apiPedidoService.buscaZplEtiquetaDanfePedido(false,this.pedido.OrderId,this.pedido.Id).subscribe((res:any,)=>{
        if(res.Data!=null && res.Data!=""){
        this.etiquetaZpl = res.Data;
        }
      },
      (error:any)=>{

      });

      },
      (error : any) => {
        this.carregandoEtiquetas = false;
      });
    
    this.tabSelected = 2;
  }

  imprimeEtiqueta(){
    if(this.etiquetaZpl!=""){
      //Imprime ZPL
      this.imprimindoEtiqueta = true;
      let This = this;
      let zpl = this.etiquetaZpl;
      BrowserPrint.getDefaultDevice("printer",function(device){
        //let zebraDevice = new Zebra.Printer(device);
        device.send(zpl,function(){          
          console.log("impressão feita");
          This.imprimindoEtiqueta = false;          
        },
        function(error){

          console.log(error);
          //tenta um reenvio
          device.send(zpl,function(){
            This.imprimindoEtiqueta = false;
          },function(error){
            alert("Erro na impressão da etiqueta, tente novamente");
            This.imprimindoEtiqueta = false;
          });
         
          
        });
        /*
        zebraDevice.isPrinterReady(function(){
          zebraDevice.write(zpl);
        },
        function(error){
          console.log(error);
        });
        */
            
      },
      function(error){
        
        alert('Não foi localizado o software Zebra Browser Print. Por favor verificar a instalação conforme o manual.')
        window.open('assets/files/manualzebra.docx');
        console.log(error);
      }
      );

    }
  }
  
  imprimeEtiquetas(){
    let zpl = this.zebraService.geraZplEtiquetas(this.pedido, this.danfePedido.NumeroNf);

    this.etiquetaZplEmbed = URL.createObjectURL(new Blob([zpl], {
      type: "text/plain"
    }));
  }

  imprimeEtiquetasRede(){
      let configuration = getLocalStorage("configuration") as IConfiguration;

      if(configuration == null || configuration.ip == null || configuration.port == null){
        this.toastService.toast("As configurações de rede da impressora não estão definidas. Por favor, acesse o Menu Superior > Configurações e preencha os dados.", ToastType.error);
        return;
      }

      let zpl = this.zebraService.geraZplEtiquetas(this.pedido, this.danfePedido.NumeroNf);

      const loadingRef = this.loadingService.show();

      this.httpClient.post("http://"+configuration.ip+":"+configuration.port+"/pstprnt", {body: new Blob([zpl])}).subscribe(res => {
        loadingRef.dismiss();  
      this.toastService.toast("Etiqueta(s) enviada(s) para a impressora com sucesso.", ToastType.success);        
      },
      err =>{
        loadingRef.dismiss();
        this.toastService.toast("Erro ao enviar etiqueta(s) para a impressora: "+err.message, ToastType.error);
        console.error(err) 
      });
  }

  carregaEtiquetas(){    
    if(this.danfePedido == null){
      return;
    }

    if(this.etiquetaEmbed != ""){
      this.tabSelected = 1;
      return;
    }

    this.carregandoEtiquetas = true;

    let zpl = this.zebraService.geraZplEtiquetas(this.pedido, this.danfePedido.NumeroNf);    

    this.zebraService.geraPdfEtiqueta(zpl).subscribe((data : any)  =>{      
      this.etiquetaEmbed = "data:application/pdf;base64,"+data.Data.Pdf;   
      this.tabSelected = 1;
      this.carregandoEtiquetas = false;
    }, err =>{
      this.carregandoEtiquetas = false;
      this.toastService.toast("Erro ao carregar prévias das etiquetas: "+err.message, ToastType.error);
      console.error(err);       
    });
  }
  
}
