import { Component, AfterViewInit, ViewChild, ElementRef,Output,EventEmitter } from "@angular/core";
import { IPaginator } from "src/app/ui/paginator/paginator.component";
import { BehaviorSubject, Subject } from "rxjs";
import { InputComponent } from "src/app/ui/input/input/input.component";
import { SelectComponent } from "src/app/ui/select/select/select.component";
import { SelectOptionComponent } from "src/app/ui/select/select-option/select-option.component";
import { ToastService } from "src/app/ui/toast/toast.service";
import { ToastType } from 'src/app/ui/toast/toast.model';
import { EnumOrdenacao } from "src/app/services/api/pedido/enum-ordenacao.enum";
import { first } from 'rxjs/operators';
import { ApiNotaServicoService } from 'src/app/services/api/notaservico/api-notaservico.service'
import { NotaServico } from "src/app/lib/dynamic-form/models/api/notaservico/notaservico";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'c-nfs-list',
  templateUrl: './nfs-list.component.html',
  styleUrls: ['./nfs-list.component.scss']
})
export class NfsListComponent implements AfterViewInit {
  @Output() setFocus = new EventEmitter();

  defaultTableColumns: string[] = [
    "Download",
    "Empresa",
    "CnpjEmissor",
    "CodigoParceiro",
    "CnpjParceiro",
    "NomeParceiro",
    "DataEmissao",
    "NotaFiscal",
    "SerieNotaFiscal",
    "ValorNota",
    "NumeroNFE",
    "NumeroDocumento",
    "Actions"
  ];

  interval: any;
  intervalDownload: any;
  downloadInProgress:Boolean;


  cnpj:string;
  codigoParceiro:string;
  nomeParceiro:string;
  notaFiscal: string;
  empresa:string;
  from:string;
  to:string;


  tabs!: {
    name: string;
    status: "EMITIDA";
    ordenacao: EnumOrdenacao;
    count: number;
    notas: [];
    permission: string;
    paginator: BehaviorSubject<IPaginator>;
  }[];

  constructor(
    private apiNotaServicoService:ApiNotaServicoService,
    private toastService: ToastService

  ) { 
    this.createTabs();

    this.buscaNotasEmitidas(true);
    //this.iniciaTimerRefresh();
  }

  ngAfterViewInit() {

  }

  reload(paginator: IPaginator, tab: any) {
    tab.paginator.next(paginator);
    this.buscaNotas(tab, true);
  }

  createTabData(data: any) {
    const tab = this.tabs.find((elem) => elem.status == "EMITIDA");

    if (tab) {
      tab.notas = data.Data;
      tab.count = data.Count;
      
    }
  }

  updateSort(
    data: { [x: string]: number },
    paginator: IPaginator,
    status: string
  ) {
    const tab = this.tabs.find((elem) => {
      return elem.status == status;
    });

    if (data.DataCadastro) {
      if (data.DataCadastro > 0) {
        tab.ordenacao = EnumOrdenacao.descendente;
      } else {
        tab.ordenacao = EnumOrdenacao.ascendente;
      }

      this.reload(paginator, tab);
    }
  }

  iniciaTimerRefresh() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.interval = setTimeout(() => {
      this.buscaNotasEmitidas(false);
    }, 15000);
  }

  iniciarTimerDownloadRefresh(id:String){
    if(this.intervalDownload){
      clearInterval(this.intervalDownload);
    }

    this.intervalDownload = setTimeout(()=>{
      this.downloadInfo(id);
    },1000);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  buscaNotasEmitidas(useLoading: boolean) {
    for (let tab of this.tabs) {
      this.buscaNotas(tab, useLoading);
    }
  }

  buscaNotas(tab: any, useLoading: boolean) {
    const paginator = tab.paginator.value;

    let skip = (paginator.page - 1) * paginator.pageLength;
    let take = paginator.pageLength;

    
    this.apiNotaServicoService
      .buscaNotas(
        useLoading,
        this.cnpj,
        this.nomeParceiro,
        this.codigoParceiro,
        this.notaFiscal,
        this.empresa,
        this.from,
        this.to,
        skip,
        take        
      )
      .subscribe(
        (res: any) => {
          this.createTabData(res);
         //this.iniciaTimerRefresh();
        },
        (err: any) => {
          //this.iniciaTimerRefresh();
        }
      );
  }

  downloadNotasFilter(){
    this.apiNotaServicoService
    .downloadNotasByFilter(
      false,
      this.cnpj,
      this.nomeParceiro,
      this.codigoParceiro,
      this.notaFiscal,
      this.empresa,
      this.from,
      this.to      
    )
    .subscribe(
      (res: any) => {
        this.downloadInProgress = true;        
        this.iniciarTimerDownloadRefresh(res.Data.Id);
        
      },
      (err: any) => {
        this.downloadInProgress = false;
      }
    );
  }

  downloadNotas(){
    const tab = this.tabs.find((elem) => elem.status == "EMITIDA");
    const notas:NotaServico[] = tab.notas;
    const ids = notas.filter( x => x.Check).map(x=>x.Id);
    console.log(ids);
    this.apiNotaServicoService
    .downloadNotasById(
      false,
      ids
    )
    .subscribe(
      (res: any) => {
        this.downloadInProgress = true;
        
        this.iniciarTimerDownloadRefresh(res.Data.Id);
        
      },
      (err: any) => {
        this.downloadInProgress = false;
      }
    );
  }

  private downloadInfo(id:String){

    this.apiNotaServicoService
    .downloadInfo(
      false,
      id
    ) .subscribe(
      (res: any) => {
        if(res.Data.Ready){
          this.downloadInProgress = false;
          this.downloadFile(res.Data.Id);
        }else{
          this.downloadInProgress=true;
          this.iniciarTimerDownloadRefresh(res.Data.Id);

        }
      },
      (err: any) => {
        this.downloadInProgress = false;
        this.iniciarTimerDownloadRefresh("0");
      }
    );
  }

  private downloadFile(id:String){    
      this.toastService.toast("Download concluido ", ToastType.success);
      
        const url= environment.api + environment.microservice.notaservico+"/dashboard/nfs/download/"+id
        window.location.href=url;
     
  }
  private createTabs() {
    this.tabs = [
      {
        name: "Notas Serviço",
        status: "EMITIDA",
        ordenacao: EnumOrdenacao.descendente,
        count: 0,
        notas: [],
        permission: "wms.nfs.listar",
        paginator: new BehaviorSubject<IPaginator>({
          isLastPage: false,
          page: 1,
          pageLength: 25,
          total: 0,
        }),
      }
    
    ];
  }

}
