import { Injectable } from '@angular/core';
import { ApiHttpClientService } from '../../api-http-client.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth.service';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { Seller } from 'src/app/lib/dynamic-form/models/api/manutencao-loja/seller';
import { EstoqueResponse } from 'src/app/lib/dynamic-form/models/api/estoque/estoque-response';



@Injectable({
  providedIn: 'root'
})
export class ApiEstoqueService {

  private baseUrl = '';
  private controller: ApiHttpClientService;

  constructor(
    private api: ApiHttpClientService,
    private authServ: AuthService
  ) {
    this.controller = this.api

      .useBaseUrl(environment.microservice.manutencaoLoja);
  }

  obterEstoque(useLoading: boolean, skuSap: string[], skuVtex: string[], centro: string, page: number = 1, take: number = 25) {
    let request = this.controller;

    if (useLoading) {
      request = request.useLoading()
    }
    let route = '/Stock';
    const params = new HttpParams()
      .append('page', page.toString())
      .append('take', take.toString());
    let body = {
      'skuSapList': skuSap,
      'skuVtexList': skuVtex,
      'centro': centro
    }
    return request
      .unwrap()
      .post<EstoqueResponse>(route, body,{ params });
  }



}
