import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { AuthGuard } from '../services/guards/auth.guard';
import { AppTemplates } from './app-template.enum';
import { LoginModule } from '../pages/login/login.module';
import { NotFoundModule } from '../pages/not-found/not-found.module';
import { DashboardModule } from '../pages/dashboard/dashboard.module';
import { EtiquetaLojasModule } from '../pages/etiqueta-lojas/etiqueta-lojas.module';
import { MonitorPagamentoModule} from '../pages/monitor-pagamento/monitor-pagamento.module';
import { DashboardNfsModule } from '../pages/dashboard-nfs/dashboard-nfs.module'
import {TransferenciaModule} from '../pages/transferencia/transferencia.module'
import { PricefyModule } from '../pages/pricefy/pricefy.module';
import { RelatorioPrecoModule } from '../pages/relatorio-preco/relatorio-preco.module';
import { DashBoardTransportadoraModule } from '../pages/dashboard-transportadora/dashboard-transportadora.module';
import { ManutencaoLojaModule } from '../pages/manutencao-loja/manutencao-loja.module';
import { ConsultaEstoqueModule } from '../pages/consulta-estoque/consulta-estoque.module';

export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => LoginModule,
    data: { template: AppTemplates.blank }
  },
  // Not found
  {
    path: 'not-found',
    loadChildren: () => NotFoundModule,
    data: { template: AppTemplates.blank }
  },
  {
    path: 'dashboard',
    loadChildren: () => DashboardModule,
    data: { template: AppTemplates.main }
  },
  {
    path: 'etiqueta-lojas',
    loadChildren: () => EtiquetaLojasModule,
    data: { template: AppTemplates.main }
  },
  {
    path:'monitor-pagamento',
    loadChildren: () => MonitorPagamentoModule ,
    data: {template: AppTemplates.main }
  },
  {
    path:'dashboard-nfs',
    loadChildren:() =>  DashboardNfsModule ,
    data: { template: AppTemplates.main}
  },
  {
    path:'transferencia',
    loadChildren: ()=> TransferenciaModule ,
    data: {template: AppTemplates.main }
  },
  {
    path:'pricefy',
    loadChildren: ()=> PricefyModule ,
    data: {template: AppTemplates.main }
  },
  {
    path:'relatorio-preco',
    loadChildren: ()=> RelatorioPrecoModule,
    data:{template: AppTemplates.main}
  },
  {
    path: 'dashboard-transportadora',
    loadChildren: ()=> DashBoardTransportadoraModule,
    data:{template: AppTemplates.main}
  },  
  {
    path:'manutencao-loja',
    loadChildren: ()=> ManutencaoLojaModule,
    data:{template: AppTemplates.main}
  },  
  {
    path:'consulta-estoque',
    loadChildren: ()=> ConsultaEstoqueModule,
    data:{template: AppTemplates.main}
  },
  // Redirect any route not founded to home
  { path: '**', redirectTo: 'not-found' }
];

// Set auth guard for all routes
// If necessary remove auth guard in specific router, just pass the route name
setAuthGuardAll('login');

/**
 * Set guard in all routes except informed path
 */
function setAuthGuardAll(...exceptions: string[]) {
  routes.forEach((elem: Route) => {
    if (!exceptions.includes(elem.path)) {
      elem.canActivate = [AuthGuard];
    }
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
