import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Seller } from 'src/app/lib/dynamic-form/models/api/manutencao-loja/seller';
import {Dock} from 'src/app/lib/dynamic-form/models/api/manutencao-loja/dock'
import { ApiManutencaoLojaService } from 'src/app/services/api/manutencao-loja/api-manutencao-loja.service';
import { ToastType } from 'src/app/ui/toast/toast.model';
import { ToastService } from 'src/app/ui/toast/toast.service';

@Component({
  selector: 'c-loja-list',
  templateUrl: './loja-list.component.html',
  styleUrls: ['./loja-list.component.scss']
})
export class LojaListComponent implements AfterViewInit {

  lojas:string ="";
  sellers: Seller[] = [];
  docks:Dock[]=[];
  flgPrincipal:boolean = false;
  flgShip:boolean = false;
  constructor(
    private apiManutencaoLojaervice:ApiManutencaoLojaService,
    private toastService:ToastService
  ) { }

  ngAfterViewInit() {

  }

  buscarLojas(){
    let lojas:string[] = this.lojas.split('\n');    
    this.apiManutencaoLojaervice.obterLojas(true,lojas)
    .subscribe(data=>{
      this.sellers = data;
      this.atualizaSellers();
    },
    (err)=>{
      this.toastService.toast("Erro ao procurar as lojas",ToastType.error);
    });
  }

  atualizaSellers(){
    this.sellers.forEach(s=>{
      s.docks.forEach(d=>{
        let dock = this.docks.find(e=>{
          return e.name == d.name
        });
        if(!dock){
          this.docks.push({name:d.name, isActive:d.isActive,allActive:true});
        }else{
          if(!d.isActive){
            dock.allActive=false;
          }
        }
      })

      this.docks.forEach(d=> d.isActive=d.allActive);
    });
  }

  togle(dock:Dock){
   this.sellers.forEach(s=>{
    s.docks.forEach(d=>{
      if(d.name==dock.name){
        d.isActive=!dock.isActive;
      }
    })
   });
  }


  atualizar(){
    if(confirm("As docas das lojas Vtex serão atualizadas conforme a configuração exibida. Tem Certeza?")){
      console.debug(this.sellers);
      this.apiManutencaoLojaervice.atualizarSeller(true,this.sellers)
      .subscribe(data=>{
        this.sellers=data;
        this.atualizaSellers();
        this.toastService.toast("Lojas atualizadas!",ToastType.success);
      },
      (err)=>{
        this.toastService.toast("Erro ao atualizar as lojas",ToastType.error);
      })
    }
  }

}
