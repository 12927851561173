import { Component, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { IPaginator } from "src/app/ui/paginator/paginator.component";
import { BehaviorSubject, Subject } from "rxjs";
import { Pagamento } from "src/app/lib/dynamic-form/models/api/pagamento/pagamento";
import { InputComponent } from "src/app/ui/input/input/input.component";
import { SelectComponent } from "src/app/ui/select/select/select.component";
import { SelectOptionComponent } from "src/app/ui/select/select-option/select-option.component";
import { ToastService } from "src/app/ui/toast/toast.service";
import { EnumOrdenacao } from "src/app/services/api/pedido/enum-ordenacao.enum";
import { EnumPagamentoStatus } from "src/app/services/api/pagamento/enum-pagamento-status.enum";
import { ApiPagamentoService } from "src/app/services/api/pagamento/api-pagamento.service";
import { first } from 'rxjs/operators';

@Component({
  selector: "c-pagamento-list",
  templateUrl: "./pagamento-list.component.html",
  styleUrls: ["./pagamento-list.component.scss"],
})
export class PagamentoListComponent implements AfterViewInit {
  defaultTableColumns: string[] = [
    "IdLoja",
    "IdPedido",
    "IdCielo",
    "Order",
    "Name",
    "Price",
    "DataCadastro",
    "ExpirationDate",
    "Actions"
  ];

  interval: any;

  lojas: string[];
  loja: string;
  from: string;
  to: string;

  tabs!: {
    name: string;
    status: EnumPagamentoStatus;
    ordenacao: EnumOrdenacao;
    count: number;
    pagamentos: Pagamento[];
    permission: string;
    paginator: BehaviorSubject<IPaginator>;
  }[];

  constructor(
    private apiPagamentoService: ApiPagamentoService,
    private toastService: ToastService
  ) {
    this.createTabs();
    this.apiPagamentoService.buscaLojas().subscribe(
      (res: any) => {
        this.lojas = res;
        this.loja = this.lojas[0];

        this.buscaPagamentoPendentes(true);
        this.iniciaTimerRefresh();
      },
      (err: any) => {
        console.log(err);
      }
    );

    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.from = firstDay.toJSON().slice(0,10);
    this.to = lastDay.toJSON().slice(0,10);
  }

  ngAfterViewInit() {

  }

  reload(paginator: IPaginator, tab: any) {
    tab.paginator.next(paginator);
    this.buscaPagamentos(tab, true);
  }

  updateSort(
    data: { [x: string]: number },
    paginator: IPaginator,
    status: EnumPagamentoStatus
  ) {
    const tab = this.tabs.find((elem) => {
      return elem.status == status;
    });

    if (data.DataCadastro) {
      if (data.DataCadastro > 0) {
        tab.ordenacao = EnumOrdenacao.descendente;
      } else {
        tab.ordenacao = EnumOrdenacao.ascendente;
      }

      this.reload(paginator, tab);
    }
  }

  iniciaTimerRefresh() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.interval = setTimeout(() => {
      this.buscaPagamentoPendentes(false);
    }, 15000);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  buscaPagamentoPendentes(useLoading: boolean) {
    for (let tab of this.tabs) {
      this.buscaPagamentos(tab, useLoading);
    }
  }

  buscaPagamentos(tab: any, useLoading: boolean) {
    const paginator = tab.paginator.value;

    let skip = (paginator.page - 1) * paginator.pageLength;
    let take = paginator.pageLength;

    this.apiPagamentoService
      .buscaPagamentos(
        useLoading,
        this.loja,
        tab.status,
        this.from,
        this.to,
        skip,
        take,
        tab.ordenacao
      )
      .subscribe(
        (res: any) => {
          this.createTabData(res, tab.status);
          this.iniciaTimerRefresh();
        },
        (err: any) => {
          this.iniciaTimerRefresh();
        }
      );
  }

  createTabData(data: any, status: EnumPagamentoStatus) {
    const tab = this.tabs.find((elem) => elem.status == status);

    if (tab) {
      tab.pagamentos = data.data;
      tab.count = data.count;
      
    }
  }

  private createTabs() {
    this.tabs = [
      {
        name: "pendente",
        status: EnumPagamentoStatus.CREATED,
        ordenacao: EnumOrdenacao.descendente,
        count: 0,
        pagamentos: [],
        permission: "wms.pedido.listarFaturados",
        paginator: new BehaviorSubject<IPaginator>({
          isLastPage: false,
          page: 1,
          pageLength: 25,
          total: 0,
        }),
      },
      {
        name: "analises",
        status: EnumPagamentoStatus.ANALYSIS,
        ordenacao: EnumOrdenacao.descendente,
        count: 0,
        pagamentos: [],
        permission: "wms.pedido.listarFaturados",
        paginator: new BehaviorSubject<IPaginator>({
          isLastPage: false,
          page: 1,
          pageLength: 25,
          total: 0,
        }),
      }      ,
      {
        name: "pago",
        status: EnumPagamentoStatus.PAID,
        ordenacao: EnumOrdenacao.descendente,
        count: 0,
        pagamentos: [],
        permission: "wms.pedido.listarPendentes",
        paginator: new BehaviorSubject<IPaginator>({
          isLastPage: false,
          page: 1,
          pageLength: 25,
          total: 0,
        }),
      },
      {
        name: "cancelados",
        status: EnumPagamentoStatus.NaoFinalizado,
        ordenacao: EnumOrdenacao.descendente,
        count: 0,
        pagamentos: [],
        permission: "wms.pedido.listarFaturados",
        paginator: new BehaviorSubject<IPaginator>({
          isLastPage: false,
          page: 1,
          pageLength: 25,
          total: 0,
        }),
      },
    ];
  }
}
