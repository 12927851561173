import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ToastType } from 'src/app/ui/toast/toast.model';
import { ApiNotaServicoService } from 'src/app/services/api/notaservico/api-notaservico.service';
import { NotaServico } from 'src/app/lib/dynamic-form/models/api/notaservico/notaservico';
import { ConfirmDialogService } from 'src/app/ui/modal/confirm-dialog.service';
import {   NotaServicoDialogService} from 'src/app/ui/modal/notaservico-dialog.service';

@Component({
  selector: 'c-nfs-list-table',
  templateUrl: './nfs-list-table.component.html',
  styleUrls: ['./nfs-list-table.component.scss']
})
export class NfsListTableComponent implements OnInit {
  @Output() listUpdated = new EventEmitter();
  @Output() setFocus = new EventEmitter();
  @Input() columns: string[];
  @Input() dataSource: object[];

  @Output() sortEvent = new EventEmitter<{ [x: string]: number }>();
  constructor(private apiNotaServicoService : ApiNotaServicoService,
    private confirmDialogService : ConfirmDialogService,
    private notaServicoDialogService : NotaServicoDialogService,
    private toastService : ToastService) { }

  ngOnInit() {
  }

  updateSort(data: { [x: string]: number }) {
    this.sortEvent.emit(data);
  }

  downloadNota(nota:NotaServico){

        
    this.apiNotaServicoService.buscaDanfeNota(true, nota.Id).subscribe((res : any) => {      
      if(res.Data != null){
        const downloadLink = document.createElement('a');
        downloadLink.href = "data:application/pdf;base64,"+res.Data;
        downloadLink.download = `${nota.NumeroNFE.trim()}-DANFE.pdf`;
        downloadLink.click();
        this.setFocus.emit();
      }else{        
        this.toastService.toast("DANFE em processo de emissão para a nota  \""+nota.NumeroNFE+"\".", ToastType.error);
      }
    },
    (error : any) => {
      this.toastService.toast("DANFE em processo de emissão para a nota \""+nota.NumeroNFE+"\".", ToastType.error);
    });

  }

  exibirNota(nota:NotaServico){
    
    this.notaServicoDialogService.confirm("Pedido: "+nota.NumeroNFE, nota)
    .then((send) => {
      this.setFocus.emit();
    })
    .catch((err) => {console.log(err)})  
  }
}
