import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './core-routing.module';
import { AppComponent } from './core.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SidebarModule } from '../ui/sidebar/sidebar.module';
import { NavbarModule } from '../ui/navbar/navbar.module';
import { CoreNavbarComponent } from './core-navbar/core-navbar.component';
import { CoreSidebarComponent } from './core-sidebar/core-sidebar.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingModule } from '../ui/loading/loading.module';
import { ImgModule } from '../ui/img/img.module';
import { IconModule } from '../ui/icon/icon.module';
import { ToastModule } from '../ui/toast/toast.module';
import { NgxMaskModule } from 'ngx-mask';
import { CoreNotificationComponent } from './core-notification/core-notification.component';
import { EmptyPatternModule } from '../ui/empty-pattern/empty-pattern.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    'assets/i18n/', // json path
    '.json'
  );
}

@NgModule({
  declarations: [
    AppComponent,
    CoreNavbarComponent,
    CoreSidebarComponent,
    CoreNotificationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
    SharedModule,
    SidebarModule,
    NavbarModule,
    ImgModule,
    IconModule,
    LoadingModule,
    ToastModule,
    EmptyPatternModule
  ],
  exports: [
    SharedModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class CoreModule { }
