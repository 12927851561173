import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, sequence, animate } from '@angular/animations';
import { ToastType } from './toast.model';
import { ToastService } from './toast.service';

@Component({
  selector: 'c-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('toast', [
      transition(':leave', [
        style({ opacity: '*', transform: 'translateX(0)' }),
        sequence([
          animate('.25s ease', style({ opacity: 0, transform: 'translateX(20px)' })),
        ])
      ]),
      transition(':enter', [
        style({ opacity: '0', transform: 'translateX(20px)', height: 0 }),
        sequence([
          animate('.1s ease', style({ opacity: '.2', transform: 'translateX(20px)', height: '*'})),
          animate('.35s ease', style({ opacity: 1, transform: 'translateX(0)' }))
        ])
      ])
    ])
  ]
})
export class ToastComponent implements OnInit {

  private messagesClass: Map<ToastType, string>;

  constructor(
    public toastService: ToastService
  ) {
    this.messagesClass = new Map([
      [undefined, undefined],
      [ToastType.default, undefined],
      [ToastType.error, '-error'],
      [ToastType.info, '-info'],
      [ToastType.success, '-success'],
      [ToastType.warning, '-warning'],
    ]);
  }

  ngOnInit() {
  }

  getCssClassByType(type: ToastType | number) {
    return this.messagesClass.get(type);
  }
}
