import { ThrowStmt } from "@angular/compiler";
import { Component, AfterViewInit, ViewChild, ElementRef,Output,EventEmitter,TemplateRef } from "@angular/core";
import { getMatIconFailedToSanitizeLiteralError } from "@angular/material";
import { DinamicaPricefy } from "src/app/lib/dynamic-form/models/api/pricefy/dinamicaPricefy";
import { FormatoPricefy } from "src/app/lib/dynamic-form/models/api/pricefy/formatoPricefy";
import { Lote } from "src/app/lib/dynamic-form/models/api/pricefy/lote";
import { LotePricefy } from "src/app/lib/dynamic-form/models/api/pricefy/lotePricefy";
import { ProdutoPricefy } from "src/app/lib/dynamic-form/models/api/pricefy/produtoPricefy";
import { SkuPricefy } from "src/app/lib/dynamic-form/models/api/pricefy/skuPricefy";
import { TemaPricefy } from "src/app/lib/dynamic-form/models/api/pricefy/temaPricefy";
import { ApiPricefyService } from "src/app/services/api/pricefy/api-pricefy.service";
import { ModalService } from "src/app/ui/modal/modal.service";
import { ToastType } from "src/app/ui/toast/toast.model";
import { ToastService } from "src/app/ui/toast/toast.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'c-pricefy-list',
  templateUrl: './pricefy-list.component.html',
  styleUrls: ['./pricefy-list.component.scss']
})
export class PricefyListComponent implements AfterViewInit {
  @ViewChild('tpProdutos',{static:false}) tpProdutos: TemplateRef<any>;
    formatos:FormatoPricefy[] = [];

    temas:TemaPricefy[]=[];

    dinamicas: any[]=[];

    tipoLotes: any[]=[
      {dsc:"Todas as Lojas",tipo:"TODAS"},
      {dsc:"Lojas RiHappy",tipo:"RH"},
      {dsc:"Lojas PBKids",tipo:"PBKIDS"},
      {dsc:"Lojas Especificas",tipo:"LOJA"},
    ]
    parcela:string = "10";
    tipoLote:string ="TODAS";
    codigoFormato:string="";
    codigoTema:string="";
    codigoDinamica:string="";
    materiais:string="";
    lote:Lote=null;
    loteProdutos:ProdutoPricefy[];
    loja:string;
    processando:boolean=false;
    dinamica?:DinamicaPricefy
    descontoPercentual?:number;
    quantidade?:number;
    produtoSecundario?:string;
    leve?:number;
    pague?:number;

  constructor(
      private apiPricefyService:ApiPricefyService,
      private toastService:ToastService,
      private modalService: ModalService
  ) { 
    const jsonLote=localStorage.getItem("pricefy-lote");
    if(jsonLote){
    this.lote = JSON.parse(jsonLote);
    }
    if(!this.lote){
      this.lote = { Id:"", TipoLote : this.tipoLote,Erro:false,Skus:null};
    }else{
      this.apiPricefyService.obterLote(true,this.lote.Id)
      .subscribe(data=>{
        this.lote=data;
        this.tipoLote = this.lote.TipoLote;
        if(this.lote.StatusLote!="NOVO"){
          this.processando=true;
        }
      })
      
    }

    this.apiPricefyService.obterDinamicas(true)
    .subscribe(data=>{
      this.dinamicas = data;
      this.codigoDinamica="_";
      this.obterDinamica();
    });

  }

  ngAfterViewInit() {

  }

  obterDinamica(){
    this.apiPricefyService.obterDinamica(true,this.codigoDinamica)
    .subscribe(data=>{
      this.dinamica=data;
      this.formatos = data.Formatos;
      this.temas=data.Temas;
      this.descontoPercentual=null;
      this.quantidade=null;
      this.produtoSecundario="";
      this.leve=null;
      this.pague=null;
    });
  }

  temCampo(campo:string){
    if(this.dinamica){
      return this.dinamica.Campos.indexOf(campo)>=0;
    }

    return false;
  }

  atualizaMateriais(evt){
    this.materiais = evt.target.value;

  }

  convertirSkus():SkuPricefy[]{
    let skus:SkuPricefy[]=[];
    let codigos:string[] = this.materiais.split('\n');
    codigos = codigos.filter(x=>x);
    codigos.forEach((e)=>{
      skus.push(
        {
          Formato:this.codigoFormato,
          Tema:this.codigoTema,
          Sku:e , 
          Dinamica: this.codigoDinamica , 
          DescontoPercentual :this.descontoPercentual,
          Quantidade:this.quantidade,
          ProdutoSecundario:this.produtoSecundario,
          Leve:this.leve,
          Pague:this.pague
        });
    });
    return skus;
  }

  excluirSkus(){
    let skus = this.convertirSkus();
    if(skus.length<=0){
      this.toastService.toast("Codigos de Materias invalidos")
      return;
    }
    this.lote.Skus=skus;
    this.apiPricefyService.excluirSkus(true,this.lote)
    .subscribe(data=>{
        this.lote=data;
        this.materiais="";
    },
    (err)=>{
      this.toastService.toast("Erro ao excluir os produtos do lote",ToastType.error);
    });
  }

  mudarTipoLote(){
    this.lote.TipoLote = this.tipoLote;
    if(this.tipoLote =="LOJA"){
      if(this.loja.length <4 || (this.lote.Lojas.length == 1 && this.lote.Lojas[0] == this.loja)){
        return
      }
      this.lote.Lojas = [this.loja];
    }else{
      this.loja="";
    }

    const codigos:SkuPricefy[] =[];
    this.lote.Skus=codigos;
    this.apiPricefyService.adicionarSkus(true,this.lote)
    .subscribe(data=>{
      this.lote=data;
      localStorage.setItem("pricefy-lote",JSON.stringify(this.lote));
      this.materiais="";
    },
    (err)=>{
      this.toastService.toast("Erro ao atualizar o lote",ToastType.error);
    }
    );
  

  }

  downloadProdutos(lote:LotePricefy){
    const url= environment.api + environment.microservice.pricefy+`/lote/${this.lote.Id}/loja/${lote.Loja}/produtos`;
    window.location.href=url;
  }

  

  adicionarSkus(){
    if(this.codigoFormato==""|| this.codigoTema =="" || this.materiais =="" || this.tipoLote==""){
      this.toastService.toast("Para adicionar produtos precisa especificar Formato,Tema e o Tipo do Lote",ToastType.error);
      return;
    }
    let skus = this.convertirSkus();
    if(skus.length<=0){
      this.toastService.toast("Codigos de Materias invalidos")
      return;
    }
    

    this.lote.TipoLote=this.tipoLote;
    this.lote.Skus=skus;
    this.apiPricefyService.adicionarSkus(true,this.lote)
      .subscribe(data=>{
        this.lote=data;
        localStorage.setItem("pricefy-lote",JSON.stringify(this.lote));
        this.materiais="";
      },
      (err)=>{
        this.toastService.toast("Erro ao adicionar os produtos",ToastType.error);
      }
      );


  }

  abrirDetalhe(lotePricefy:LotePricefy){
    this.loteProdutos = lotePricefy.Produtos;
    this.modalService.open(this.tpProdutos);
  }

  processarLote(){
    this.apiPricefyService.processarLote(true,this.lote.Id)
      .subscribe(data=>{
        this.lote = data;
        localStorage.setItem("pricefy-lote",JSON.stringify(this.lote));    
        this.processando=true;
        setTimeout(() => {
          this.obterLote()
        }, 1000);

      },
      (err)=>{
        this.toastService.toast("Erro ao processar o lote",ToastType.error);
      });
  }

  obterLote(){
    this.apiPricefyService.obterLote(false,this.lote.Id)
      .subscribe(data=>{
        this.lote=data;
        localStorage.setItem("pricefy-lote",JSON.stringify(this.lote));    

        if(this.lote.StatusLote=="PROCESSANDO"){
          setTimeout(() => {
            this.obterLote()
          }, 1000);
        }

      })
  }



  novoLote(){
    if(confirm("Tem certeza que deseja criar um novo lote?")){
    this.lote = { Id:"", TipoLote : this.tipoLote,Erro:false,Skus:null};
    localStorage.setItem("pricefy-lote","");    
    this.processando=false;
    }
  }
}
