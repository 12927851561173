import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { IPaginator } from 'src/app/ui/paginator/paginator.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { IResponse } from 'src/app/models/api/response.interface';
import { skip, debounceTime, take } from 'rxjs/operators';
import { ApiPedidoService } from 'src/app/services/api/pedido/api-pedido.service';
import { Pedido } from 'src/app/lib/dynamic-form/models/api/pedido/pedido';
import { InputComponent } from 'src/app/ui/input/input/input.component';
import { EnumTabStatus } from 'src/app/services/api/pedido/enum-tab-status.enum';
import { ConfirmDialogService } from 'src/app/ui/modal/confirm-dialog.service';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ToastType } from 'src/app/ui/toast/toast.model';
import { DanfeDialogService } from 'src/app/ui/modal/danfe-dialog.service';
import { EnumOrdenacao } from 'src/app/services/api/pedido/enum-ordenacao.enum';
import { ApiOmniService } from 'src/app/services/api/omni/api-omni.service';
import { listLazyRoutes } from '@angular/compiler/src/aot/lazy_routes';

@Component({
  selector: 'c-dashboard-transportadora',
  templateUrl: './dashboard-transportadora.component.html',
  styleUrls: ['./dashboard-transportadora.component.scss']
})
export class DashboardTransportadoraComponent implements AfterViewInit {

 show : boolean = true;
 pedidos: string ;
  dataSource: any;
  defaultTableColumns: string[] = ['orderId', 'status','transportadora', 'seller' ,'actions'];
   status = {
    "order-created": "Processando",
    "order-accepted": "Pedido aceito",
    "cancel": "Iniciando cancelamento",
    "on-order-completed": "Processando",
    "on-order-completed-ffm": "Processando",
    "payment-approved": "Pagamento aprovado",
    "payment-pending": "Pagamento pendente",
    "request-cancel": "Cancelamento solicitado",
    "canceled": "Cancelado",
    "window-to-change-payment": "Janela para mudança de pagamento",
    "window-to-change-seller": "Janela para mudança de seller",
    "waiting-for-authorization": "Aguardando autorização",
    "waiting-for-fulfillment": "Aguardando fulfillment",
    "waiting-for-manual-authorization": "Aguardando autorização para despachar",
    "window-to-cancel": "Carência para cancelamento",
    "invoice": "Verificando fatura",
    "invoiced": "Faturado",
    "ready-for-handling": "Pronto para manuseio",
    "start-handling": "Iniciar manuseio",
    "cancellation-requested": "Cancelamento solicitado",
    "handling": "Preparando entrega"
  };
  
  constructor(private apiOmniService : ApiOmniService,
    private toastService : ToastService
  ) {
  }

  ngAfterViewInit() {
  }

  buscar(){
  let lista = this.pedidos.trim().split(/\s+/);
  if(lista.length > 50){
    this.toastService.toast("Por favor envie menos de 50 pedidos por vez.", ToastType.error);
    return;
  }
  lista = lista.filter(function (x, i, a) { 
    return a.indexOf(x) === i; 
  });
   this.apiOmniService.buscarStatusPedido(lista).subscribe(
      (res) => {      
        this.dataSource = res;
      },
      (err) => {        
      this.toastService.toast("Erro ao consultar pedido.", ToastType.error);
      }
    );;
  console.log(lista);
  }
  reenviarPedido(orderId){

    const ped = { "OrderId": orderId};

    this.apiOmniService.reenviarPedido(ped).subscribe(
      (res) => {  
          this.toastService.toast("Pedido "+orderId+" reenviado.", ToastType.success);
      },
      (err) => {        
      this.toastService.toast("Erro ao reenviar pedido.", ToastType.error);
      }
    );
  }
    reenviarTodosPedidos(){

      const list = []
    if(this.dataSource.length > 50){
      this.toastService.toast("Por favor envie menos de 50 pedidos por vez.", ToastType.error);
    }
    else{
       this.dataSource.forEach(async x => {
        const ped = { "OrderId": x.OrderId};
        list.push(ped);
      });
      console.log(list);
      this.apiOmniService.reenviarPedidos(list).subscribe(
        (res) => {  
            this.toastService.toast("Pedidos reenviados", ToastType.success);
        },
        (err) => {        
        this.toastService.toast("Erro ao reenviar pedido.", ToastType.error);
        }
      );
    }
}

}