import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Pedido } from 'src/app/lib/dynamic-form/models/api/pedido/pedido';
import { ApiPedidoService } from 'src/app/services/api/pedido/api-pedido.service';
import { ItemPedidoVtex } from 'src/app/lib/dynamic-form/models/api/pedido/item-pedido-vtex';

@Component({
  selector: 'c-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
  @Output() confirm: EventEmitter<boolean> = new EventEmitter();
  @Input() message: string;
  @Input() pedido: Pedido;
  private subscription: Subscription;
  form: FormGroup;
  skusPedidoVtex: ItemPedidoVtex[];

  readonly destroy: () => void;
  readonly beforeDestroy = (from: 'container' | 'content' | 'outside') => from != 'content' && this.confirm.next(false);

  constructor(private fb: FormBuilder, private apiPedidoService : ApiPedidoService) { }  

  defaultTableColumns: string[] = ['sku', 'nome', 'quantidade']; 

  ngOnInit() {
    this.subscription = this.confirm.pipe(
      first()
    ).subscribe(() => {
      this.destroy();
    });

    this.form = this.fb.group({
      volumes: [null, [Validators.required]]
    });

    this.skusPedidoVtex = new Array();

    for(var item of this.pedido.PedidoVtex.Items){
      if(item.Components != null && item.Components.length > 0){
        
        for(var component of item.Components){
          component.Name = component.Name + " (KIT)";
          this.skusPedidoVtex.push(component);
        }
        
        continue;
      }

      this.skusPedidoVtex.push(item);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  confirmarQuantidade(){
    this.apiPedidoService.enviaQuantidadeVolumes(this.pedido, this.form.get('volumes').value).subscribe((res : any) =>{
      this.confirm.emit(true);
    })
  }
  
}
