import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiPedidoService } from 'src/app/services/api/pedido/api-pedido.service';
import { Pedido } from 'src/app/lib/dynamic-form/models/api/pedido/pedido';
import { ToastType } from 'src/app/ui/toast/toast.model';
import { ToastService } from 'src/app/ui/toast/toast.service';
import { ConfirmDialogService } from 'src/app/ui/modal/confirm-dialog.service';
import { DanfeDialogService } from 'src/app/ui/modal/danfe-dialog.service';
import { ApiJobsService } from 'src/app/services/api/jobs/api-jobs.service';
import { Etiqueta } from 'src/app/lib/dynamic-form/models/api/pedido/etiqueta';
import { JobEtiqueta } from 'src/app/models/api/job-etiqueta.interface';

@Component({
  selector: 'c-label-list-table',
  templateUrl: './label-list-table.component.html',
  styleUrls: ['./label-list-table.component.scss']
})
export class LabelListTableComponent implements OnInit {
  @Output() listUpdated = new EventEmitter();
  @Output() setFocus = new EventEmitter();
  @Input() columns: string[];
  @Input() dataSource: object[];

  cancelStatuses : string[] = ["cancel", "canceled", "cancellation-requested", "request-cancel"]

  constructor(private apiJobsService : ApiJobsService,
    private toastService : ToastService,
    private confirmDialogService : ConfirmDialogService,
    private danfeDialogService : DanfeDialogService
  ) {
    
  }

  ngOnInit() {    
  }

  visualizarDanfe(pedido : Pedido){
    
    if(this.cancelStatuses.includes(pedido.PedidoVtex.Status)){
      this.toastService.toast("Pedido \""+pedido.OrderId+"-"+pedido.Id+"\" está CANCELADO na VTEX.", ToastType.error);
      return;
    }
    
    this.danfeDialogService.confirm("Pedido: "+pedido.OrderId+"-"+pedido.Id, pedido)
    .then((send) => {
      this.setFocus.emit();
    })
    .catch((err) => {console.log(err)})  
  }

  downloadEtiqueta(etiqueta : JobEtiqueta){
    
    this.apiJobsService.buscaPdf(true, etiqueta.Id).subscribe((res : any) => {      
      if(res.Data != null){
        const downloadLink = document.createElement('a');
        downloadLink.href = "data:application/pdf;base64,"+res.Data;
        downloadLink.download = `${res.Nome}`;
        downloadLink.target = "_blank";
        downloadLink.click();
      }else{
        this.toastService.toast("PDF não disponível para o arquivo \""+etiqueta.NomeArquivo+"\".", ToastType.error);
      }
    },
    (error : any) => {
      this.toastService.toast("PDF não disponível para o arquivo \""+etiqueta.NomeArquivo+"\".", ToastType.error);
    });
  }

  downloadErros(etiqueta : JobEtiqueta){
    
    this.apiJobsService.buscaErros(true, etiqueta.Id).subscribe((res : any) => {      
      if(res.Data != null){
        const downloadLink = document.createElement('a');
        downloadLink.href = "data:application/txt;base64,"+res.Data;
        downloadLink.download = `${res.Nome}`;
        downloadLink.target = "_blank";
        downloadLink.click();
      }else{
        this.toastService.toast("Logs de erro não disponível para o arquivo \""+etiqueta.NomeArquivo+"\".", ToastType.error);
      }
    },
    (error : any) => {
      this.toastService.toast("Logs de erro não disponível para o arquivo \""+etiqueta.NomeArquivo+"\".", ToastType.error);
    });
  }
}
